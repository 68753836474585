import { useEffect, useRef } from "react";

export function useMousePosition(): MouseCoords {
    let coords = useRef({ x: 0, y: 0 });

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => window.removeEventListener('mousemove', onMouseMove);
    });

    return coords;

    function onMouseMove(event: MouseEvent) {
        coords.current = { x: event.pageX, y: event.pageY };
    }
}

export interface MouseCoords {
    current: {
        x: number,
        y: number
    }
}