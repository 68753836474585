import { IChildren, useMappedRef } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useContext, useRef } from "react";
import * as ReactDOM from "react-dom";
import { AnimationDefinition } from "stylemap";
import { noSpacing } from "./Box";
import { duration, standardSpacing, zIndexes } from "./CommonStyles";
import { findScrollParent } from "./ScrollParent";
import { animation, ease, style, Styled, TransformFunctions } from "./styling";

interface Sides<T> {
    top: T;
    bottom: T;
    left: T;
    right: T;
}

export type Side = keyof Space;
type End = 'start' | 'end';
export type Alignment = End | 'center';

interface Space extends Sides<number> { }

interface ClientPosition {
    left: number;
    top: number;
}

export interface IPopupProps {
    side?: Side;
    align?: Alignment;
    minWidth?: number;
    minHeight?: number;
    show: boolean;
    anchor?: IPopupAnchorContext;
    anchorPosition?: IPopupAnchorContext;
    anchorAlign?: IPopupAnchorContext;
    anchorOffset: number;
    fixed?: boolean;
    noAnimation?: boolean;
}

Popup.defaultProps = {
    anchorOffset: 0
};

export { Popup };

function Popup(props: IPopupProps & IChildren) {
    let [popupRect, ref] = useMappedRef(e => e.getBoundingClientRect(), [props.show]);
    let contextAnchor = useContext(AnchorContext);

    if (props.show) {
        let { popup, inner } = popupStyles(popupRect, ref.current);
        let popupElement = <Styled.div styles={[noSpacing, popupBase]} inline={popup} ref={ref as React.Ref<any>}>
            <Styled.div styles={popupInner} inline={inner}>{props.children}</Styled.div>
        </Styled.div>;
        return props.fixed
            ? ReactDOM.createPortal(popupElement, document.body)
            : popupElement;
    }

    return null;

    function popupStyles(popupRect: ClientRect | undefined, popupElement: Element | null): { popup: React.CSSProperties, inner: React.CSSProperties } {
        if (!popupRect || !popupElement)
            return { popup: {}, inner: {} };

        let { positionAnchor, alignmentAnchor } = getAnchorRects();

        let minWidth = props.minWidth || popupRect.width;
        let minHeight = props.minHeight || popupRect.height;

        let preferredSide = props.side || 'bottom';
        let preferredAlign = props.align || 'start';

        let containerRect = getContainerRect(popupElement);
        let offsetPosition = getOffsetPosition(popupElement as HTMLElement);
        let offsetScroll = getOffsetScrollPosition(popupElement as HTMLElement);

        let positioningSpace = spaceAround(positionAnchor, containerRect, { horizontal: -props.anchorOffset, vertical: -props.anchorOffset });
        let alignmentSpace = spaceAround(alignmentAnchor, containerRect);

        let side = pickSide(preferredSide, positioningSpace, minWidth, minHeight);
        let align = pickAlignment(preferredAlign, alignmentAnchor, alignmentSpace, side, minWidth, minHeight);

        return {
            popup: {
                visibility: 'visible',
                position: props.fixed ? 'fixed' : 'absolute',
                ...positionAttachment(positioningSpace, side, positionAnchor, popupRect, offsetPosition, offsetScroll),
                ...alignmentAttachment(alignmentSpace, side, align, alignmentAnchor, popupRect, offsetPosition, offsetScroll),
                ...directionTransform(side, align)
            },
            inner: {
                animationName: props.noAnimation ? '' : slideAnimations[side].animationName as string,
            }
        };
    }

    function getAnchorRects() {
        let positionAnchor = props.anchorPosition && props.anchorPosition.getRect()
            || props.anchor && props.anchor.getRect()
            || contextAnchor.getRect();
        let alignmentAnchor = props.anchorAlign && props.anchorAlign.getRect()
            || positionAnchor;
        return { positionAnchor, alignmentAnchor };
    }

    function getContainerRect(popupElement: Element): ClientRect {
        let container = props.fixed ? document.documentElement : findScrollParent(popupElement);
        let width = container.clientWidth;
        let height = container.clientHeight;
        let rect = container.getBoundingClientRect();
        return {
            top: rect.top,
            bottom: rect.top + height,
            left: rect.left,
            right: rect.left + width,
            width, height
        };
    }

    function getOffsetPosition(element: HTMLElement): ClientPosition {
        return props.fixed
            ? { left: 0, top: 0 }
            : element.offsetParent!.getBoundingClientRect();
    }

    function getOffsetScrollPosition(element: HTMLElement): ClientPosition {
        return props.fixed
            ? { left: 0, top: 0 }
            : { left: element.offsetParent!.scrollLeft, top: element.offsetParent!.scrollTop };
    }

    function spaceAround(rect: ClientRect, containerRect: ClientRect, extraSpace = { vertical: 0, horizontal: 0 }) {
        return {
            top: rect.top - standardSpacing + extraSpace.vertical - containerRect.top,
            bottom: containerRect.bottom - rect.bottom - standardSpacing + extraSpace.vertical,
            left: rect.left - standardSpacing + extraSpace.horizontal - containerRect.left,
            right: containerRect.right - rect.right - standardSpacing + extraSpace.horizontal
        };
    }

    function pickSide(side: Side, space: Space, minWidth: number, minHeight: number): Side {
        let enoughSpace = isTopOrBottom(side)
            ? space[side] >= minHeight
            : space[side] >= minWidth;

        return enoughSpace || space[side] > space[oppositeSides[side]] ? side : oppositeSides[side];
    }

    function pickAlignment(align: Alignment, alignmentAnchor: ClientRect, space: Space, positionSide: Side, minWidth: number, minHeight: number): Alignment {
        let requiredWidth = minWidth - alignmentAnchor.width;
        let requiredHeight = minHeight - alignmentAnchor.height;

        let horizontalAlignment = isTopOrBottom(positionSide);

        if (align == 'center') {
            let requiredSpace = horizontalAlignment
                ? requiredWidth / 2
                : requiredHeight / 2;
            let enoughSpace = horizontalAlignment
                ? space.left >= requiredSpace && space.right >= requiredSpace
                : space.top >= requiredSpace && space.bottom >= requiredSpace;

            if (enoughSpace)
                return align;
            else
                align = 'start';
        }

        let idealContentSide = horizontalAlignment
            ? (align == 'start' ? 'right' : 'left')
            : (align == 'start' ? 'bottom' : 'top') as Side;

        let enoughSpace = horizontalAlignment
            ? space[idealContentSide] >= requiredWidth
            : space[idealContentSide] >= requiredHeight;

        return enoughSpace || space[idealContentSide] > space[oppositeSides[idealContentSide]]
            ? align
            : oppositeEnds[align];
    }

    function positionAttachment(space: Space, side: Side, positionAnchor: ClientRect, popupRect: ClientRect, offsetPosition: ClientPosition, offsetScroll: ClientPosition): React.CSSProperties {
        let attachmentSide = getAttachmentSide(side);
        let anchorOffset = side == 'bottom' || side == 'right' ? props.anchorOffset : -props.anchorOffset;
        let attachment = { [attachmentSide]: Math.round(positionAnchor[side] + anchorOffset - offsetPosition[attachmentSide] + offsetScroll[attachmentSide]) };

        let length = isTopOrBottom(side) ? 'height' : 'width' as keyof ClientRect;
        let sizeLimit = space[side] < popupRect[length] ? { [length]: space[side] } : {};

        return { ...attachment, ...sizeLimit };
    }

    function alignmentAttachment(space: Space, position: Side, alignment: Alignment, alignmentAnchor: ClientRect, popupRect: ClientRect, offsetPosition: ClientPosition, offsetScroll: ClientPosition): React.CSSProperties {
        let length = isTopOrBottom(position) ? 'width' : 'height' as keyof ClientRect;

        if (alignment == 'center') {
            let attachmentSide: Side = isTopOrBottom(position)
                ? 'left'
                : 'top';

            let availableSpace = space[attachmentSide] + alignmentAnchor[length] + space[oppositeSides[attachmentSide]];
            let sizeLimit = availableSpace < popupRect[length] ? { [length]: availableSpace } : {};

            let attachment = { [attachmentSide]: Math.round(alignmentAnchor[attachmentSide] + alignmentAnchor[length] / 2 - popupRect[length] / 2 - offsetPosition[attachmentSide] + offsetScroll[attachmentSide]) };

            return { ...attachment, ...sizeLimit };
        } else {
            let alignmentSide: Side = isTopOrBottom(position)
                ? alignment == 'start' ? 'left' : 'right'
                : alignment == 'start' ? 'top' : 'bottom';

            let alignmentPosition = alignmentAnchor[alignmentSide];
            let sizeLimit = {} as React.CSSProperties;

            let spaceDeficit = alignmentAnchor[length] - popupRect[length] - space[oppositeSides[alignmentSide]];
            if (spaceDeficit > 0) {
                let availableSpaceInOppositeDirection = space[alignmentSide] - alignmentAnchor[length];
                let shiftDirection = alignmentSide == 'left' || alignmentSide == 'top' ? -1 : 1;

                if (spaceDeficit > availableSpaceInOppositeDirection) {
                    alignmentPosition += availableSpaceInOppositeDirection * shiftDirection;
                    sizeLimit[length] = space[oppositeSides[alignmentSide]] + availableSpaceInOppositeDirection;
                } else {
                    alignmentPosition += spaceDeficit * shiftDirection;
                }
            }

            let attachmentSide = getAttachmentSide(oppositeSides[alignmentSide]);
            let attachment = { [attachmentSide]: Math.round(alignmentPosition - offsetPosition[attachmentSide] + offsetScroll[attachmentSide]) };

            return { ...attachment, ...sizeLimit };
        }
    }

    function directionTransform(side: Side, align: Alignment): React.CSSProperties {
        let up100 = 'translateY(-100%)';
        let left100 = 'translateX(-100%)';

        let sideTransform = side == 'top' ? up100
            : side == 'left' ? left100
                : '';

        let alignTransform = align == 'end'
            ? isTopOrBottom(side) ? left100 : up100
            : '';

        return { transform: `${sideTransform} ${alignTransform}` };
    }
}

function getAttachmentSide(side: Side): 'top' | 'left' {
    return isTopOrBottom(side) ? 'top' : 'left';
}

function isTopOrBottom(side: Side) {
    return side == 'top' || side == 'bottom';
}

let oppositeSides: Sides<Side> = {
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top'
};

let oppositeEnds = {
    start: 'end' as Alignment,
    end: 'start' as Alignment,
};

let popupBase = style('popup', {
    zIndex: zIndexes.popup,
    margin: 0,
    visibility: 'hidden',
    position: 'absolute'
});

let popupInner = style('popup-inner', {
    height: '100%',
    width: '100%',
    animationDuration: duration.appear,
    animationTimingFunction: ease.enter
});

let slideAnimations: Sides<AnimationDefinition> = {
    top: slide('slideUp', { translateY: standardSpacing }),
    bottom: slide('slideDown', { translateY: -standardSpacing }),
    left: slide('slideLeft', { translateX: standardSpacing }),
    right: slide('slideRight', { translateX: -standardSpacing })
}

function slide(name: string, transform: TransformFunctions) {
    return animation(name, { from: { transform, opacity: 0 } });
}

export interface IPopupAnchorProps {
    children: React.ReactNode | ((anchor: IPopupAnchorContext) => React.ReactNode);
}

export interface IPopupAnchorContext {
    getRect(): ClientRect;
}

let AnchorContext = React.createContext(undefined! as IPopupAnchorContext);

export const PopupAnchor = React.memo(function PopupAnchor(props: IPopupAnchorProps) {
    let firstChildRef = useRef<Element>(null)
    let context = getAnchorContext(firstChildRef);
    let children = typeof props.children == 'function'
        ? (props.children as Function)(context)
        : props.children;
    let [first, ...rest] = React.Children.toArray(children);
    let firstWithRef = React.cloneElement(first, { ref: firstChildRef });

    return <AnchorContext.Provider value={context}>
        {[firstWithRef, ...rest]}
    </AnchorContext.Provider>;
});

export function usePopupAnchor<TElement extends Element>() {
    let ref = useRef<TElement>(null);
    return [ref, getAnchorContext(ref)] as const;
}

export function getAnchorContext(ref: React.RefObject<Element | null>): IPopupAnchorContext {
    return {
        getRect: () => ref.current && ref.current.getBoundingClientRect()
            || { left: 0, right: 0, top: 0, bottom: 0, width: 0, height: 0 }
    };
}
