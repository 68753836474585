import { useRef } from "react";

export function useTimeout() {
    let timeout = useRef(null as any);

    return [
        (fn: () => void, delayMs: number) => {
            clearTimeout(timeout.current);
            if (delayMs)
                timeout.current = setTimeout(fn, delayMs);
            else
                fn();
        },
        () => clearTimeout(timeout.current)
    ] as [(fn: () => void, delayMs: number) => void, () => void];
}