import { forwardRef, IChildren } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { Row } from "./Box";
import { Clickable } from "./Clickable";
import { defaultFontFamily, duration, halfSpacing, labelFontSize, lightGreyColor, roundedBorders, standardBorder, standardBorderWidth, standardFormControlHeight, standardSpacing } from "./CommonStyles";
import { useFormButtonClick } from "./Form";
import { Loading, sizes } from "./Loading";
import { Elevation, shadow } from "./shadow";
import { style, StyleCollection, Styled, transition } from "./styling";

export interface IButtonProps extends React.PropsWithoutRef<React.HTMLProps<HTMLButtonElement>> {
    dangerous?: boolean;
    grey?: boolean;
    styles?: StyleCollection;
    isLoading?: boolean;
    hideRipple?: boolean;
    onClick?: () => void;
    outlined?: boolean;
    contained?: boolean;
}

export const Button = forwardRef(function Button(props: IButtonProps, ref: React.Ref<HTMLButtonElement>) {
    let { dangerous, grey, styles, onClick, isLoading, hideRipple, disabled, outlined, contained, type, ...buttonProps } = props;
    let isDisabled = disabled || isLoading;

    let buttonClass = contained || isLoading ? containedButton
        : outlined ? outlinedButton
            : flatButton;

    let formClick = useFormButtonClick(type, onClick);

    return <Clickable
        color={dangerous ? 'dangerous' : grey ? 'darkGrey' : 'default'}
        invert={contained}
        hideRipple={hideRipple || isLoading}
        disabled={isDisabled}
        onClick={isLoading ? undefined : formClick}
        styles={[buttonWrapper, buttonClass, isDisabled && disabledButton]}>
        {({ getFocusProps }) =>
            <Styled.button
                ref={ref}
                type="button"
                role="button"
                styles={[buttonStyle, isLoading && loadingButton, styles]} /* disable with styling so mouse events are still triggered, Clickable prevents onClick */
                tabIndex={isDisabled ? -1 : undefined} /* prevent keyboard navigation if disabled */
                aria-disabled={isDisabled}
                {...getFocusProps(buttonProps)}>
                <Row noSpacing alignItems="center">
                    {props.children}
                    <Row styles={[spinner, isLoading && loadingSpinner]}>
                        {isLoading && <Loading size="medium" />}
                    </Row>
                </Row>
            </Styled.button>
        }
    </Clickable>;
});

export const buttonHeight = standardFormControlHeight;

let buttonWrapper = style('button-wrapper', {
    // Add margin to Clickable transition, for switching between contained and flat for loading,
    // and boxShadow for switching between contained and loading
    transition: [
        transition('background', duration.small),
        transition('margin', duration.small),
        transition('box-shadow', duration.medium)
    ]
});

let outlinedButton = style('button-outlined', {});
let containedButton = style('button-contained', {
    ...shadow(Elevation.RaisedButton),
    ':active': shadow(Elevation.PressedRaisedButton)
});
let flatButton = style('button-flat', {});

let buttonStyle = style('button-inner', {
    ...labelFontSize,
    ...defaultFontFamily,
    fontWeight: 'bold',
    height: buttonHeight,
    border: 'none',
    ...roundedBorders,
    boxSizing: 'border-box',
    textDecoration: 'none',
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'inherit',
    background: 'transparent',
    transition: transition(['background', 'padding'], duration.small),
    color: 'currentColor',
    ':focus': {
        outline: 'none'
    },
    $: {
        [`.${outlinedButton} &`]: {
            padding: [standardSpacing / 2 - standardBorderWidth, standardSpacing - standardBorderWidth],
            border: standardBorder()
        },
        [`.${containedButton} &`]: {
            padding: [standardSpacing / 2 - standardBorderWidth, standardSpacing - standardBorderWidth],
            border: standardBorder('transparent')
        },
        [`.${flatButton} &`]: {
            padding: standardSpacing / 2 - standardBorderWidth,
            border: standardBorder('transparent')
        }
    }
});

let loadingButton = style('button-loading', {
    background: lightGreyColor
});

let disabledButton = style('button-disabled', {
    boxShadow: 'none'
});

let spinner = style('button-spinner', {
    marginLeft: 0,
    width: 0,
    transform: { scale: 0 },
    transition: transition('all', duration.medium)
});

let loadingSpinner = style('button-spinner-loading', {
    marginLeft: halfSpacing,
    width: sizes.medium,
    transform: { scale: 1 }
});


export const ButtonList = forwardRef(function ButtonList(props: { align?: 'start' | 'end' } & IChildren, ref: React.Ref<any>) {
    return <Row ref={ref} justifyContent={'flex-' + (props.align || 'end') as any} noSpacing styles={buttonListSpacing}>{props.children}</Row>;
});

let buttonListSpacing = style('buttonList', {
    $: {
        [`> .${buttonWrapper} + .${buttonWrapper}`]: {
            marginLeft: standardSpacing
        },
        [`> * + .${flatButton}.${buttonWrapper}`]: {
            marginLeft: standardSpacing / 2
        },
        [`> .${flatButton}.${buttonWrapper} + *`]: {
            marginLeft: standardSpacing / 2
        }
    }
});
