import { dataProps } from "@zap/utils/lib/ReactHelpers";
import * as React from 'react';
import { duration, errorColor, standardBorderColor, successColor, zIndexes } from './CommonStyles';
import { Progress } from './Progress';
import { important, lighten, style, transition } from './styling';

export interface IFieldUnderlineProps<T> {
    isFocused?: boolean;
    isValid?(value: T): (boolean | undefined);
    isLoading?(value: T): (boolean | undefined);
    disabled?: boolean;
}

export interface IUnderlineProps {
    isFocused?: boolean;
    isValid?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
}

export function Underline(props: IUnderlineProps) {
    let color = props.isValid === true ? successColor
        : props.isValid === false ? errorColor
            : props.isFocused || props.isLoading ? undefined // Default progress color
                : standardBorderColor;

    return <Progress indeterminate={!props.disabled && props.isLoading} max={1} value={1}
        foregroundColor={color}
        backgroundColor={color && lighten(color, 1.3)}
        styles={[
            underline,
            props.disabled && hidden,
            props.isFocused && focused,
            (props.isFocused || props.isLoading) ? thick : thin
        ]}
        {...dataProps(props)} />;
}

let thin = style('is-underline-thin', { height: important(1) });
let thick = style('is-underline-thick', { height: important(2) });

let hidden = style('is-underline-hidden', { visibility: 'hidden' });

let underline = style('underline', {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    transition: transition('height', duration.small, 'ease')
});

let focused = style('is-underline-focused', {
    zIndex: zIndexes.popup + 1, // So combo dropdown doesn't cover while it's animating in
})