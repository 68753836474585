import "./extensions";

export function getPropertyDescriptor<T, P extends keyof T>(obj: T, key: P): TypedPropertyDescriptor<T[P]>;
export function getPropertyDescriptor(obj: any, key: PropertyKey): PropertyDescriptor | undefined;
export function getPropertyDescriptor(obj: any, name: PropertyKey) {
    do {
        let descriptor = Object.getOwnPropertyDescriptor(obj, name);
        if (descriptor)
            return descriptor;

        obj = Object.getPrototypeOf(obj);
    } while (obj);
    return undefined as any;
}

export function values<T>(obj: { [key: string]: T }): T[] {
    return Object.entries(obj).map(([, value]) => value);
}

export function isEmptyObject(obj: Object) {
    return Object.keys(obj).length === 0;
}

export function filterObject<T extends object>(obj: T, predicate: (key: keyof T, value: T[keyof T]) => boolean): Partial<T> {
    return Object.entries(obj)
        .filter(([key, value]) => predicate(key as keyof T, value))
        .toObject(e => e[0], e => e[1]) as Partial<T>;
}

export function mapValues<T extends object, U>(obj: T, select: (value: T[keyof T], key: keyof T) => U): { [P in keyof T]: U } {
    return Object.entries(obj)
        .map(([key, value]) => [key as keyof T, select(value, key as keyof T)])
        .toObject(e => e[0] as string, e => e[1]) as { [P in keyof T]: U };
}