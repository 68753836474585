import { boxShadow, hsla, Styles, transition } from "./styling";

// From https://material.io/guidelines/material-design/elevation-shadows.html#elevation-shadows-elevation-android
export enum Elevation {
    None = 0,
    Switch = 1,
    Card = 2,
    RaisedButton = 2,
    SearchBar = 2,
    RefreshIndicator = 3,
    ScrolledSearchBar = 3,
    AppBar = 4,
    FAB = 6,
    Snackbar = 6,
    BottomNavigationBar = 8,
    Menu = 8,
    PickedUpCard = 8,
    PressedRaisedButton = 8,
    PressedFAB = 12,
    NavDrawer = 16,
    RightDrawer = 16,
    ModalBottomSheet = 16,
    Dialog = 24,
    Picker = 24
}

let keyPrenumbra = hsla(0, 0, 0, 0.14);
let ambientShadow = hsla(0, 0, 0, 0.12);
let keyUmbra = hsla(0, 0, 0, 0.2);

// Shadow values from https://github.com/PolymerElements/paper-styles/blob/master/shadow.html
export function shadow(elevation: Elevation): Styles {
    switch (elevation) {
        case 0:
            return shadowStyle('none');
        case 1:
            return shadowStyle(
                boxShadow(0, 3, 1, -2, keyPrenumbra),
                boxShadow(0, 2, 2, 0, ambientShadow),
                boxShadow(0, 1, 5, 0, keyUmbra));
        case 2:
            return shadowStyle(
                boxShadow(0, 2, 2, 0, keyPrenumbra),
                boxShadow(0, 1, 5, 0, ambientShadow),
                boxShadow(0, 3, 1, -2, keyUmbra));
        case 3:
            return shadowStyle(
                boxShadow(0, 3, 4, 0, keyPrenumbra),
                boxShadow(0, 1, 8, 0, ambientShadow),
                boxShadow(0, 3, 3, -2, keyUmbra));
        case 4:
            return shadowStyle(
                boxShadow(0, 4, 5, 0, keyPrenumbra),
                boxShadow(0, 1, 10, 0, ambientShadow),
                boxShadow(0, 2, 4, -1, keyUmbra));
        case 6:
            return shadowStyle(
                boxShadow(0, 6, 10, 0, keyPrenumbra),
                boxShadow(0, 1, 18, 0, ambientShadow),
                boxShadow(0, 3, 5, -1, keyUmbra));
        case 8:
            return shadowStyle(
                boxShadow(0, 8, 10, 1, keyPrenumbra),
                boxShadow(0, 3, 14, 2, ambientShadow),
                boxShadow(0, 5, 5, -3, keyUmbra));
        case 12:
            return shadowStyle(
                boxShadow(0, 12, 16, 1, keyPrenumbra),
                boxShadow(0, 4, 22, 3, ambientShadow),
                boxShadow(0, 6, 7, -4, keyUmbra));
        case 16:
            return shadowStyle(
                boxShadow(0, 16, 24, 2, keyPrenumbra),
                boxShadow(0, 6, 30, 5, ambientShadow),
                boxShadow(0, 8, 10, -5, keyUmbra));
        case 24:
            return shadowStyle(
                boxShadow(0, 24, 38, 3, keyPrenumbra),
                boxShadow(0, 9, 46, 8, ambientShadow),
                boxShadow(0, 11, 15, -7, keyUmbra));
        default:
            throw new Error('Unexpected shadow elevation: ' + elevation);
    }
}

function shadowStyle(...shadows: string[]): Styles {
    return { boxShadow: shadows };
}