import * as React from 'react';
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import { filterObject } from "./Object";

export function cloneAndGetRef(node: React.ReactNode, getRef: (ref: Element) => void, props: object = {}) {
    return React.cloneElement(React.Children.only(node as any), {
        ref: (element: React.ReactInstance) => {
            let ref = element && findDOMNode(element);
            if (isElement(ref))
                getRef(ref);
        },
        ...props
    });
}

function isElement(element: Element | Text | null): element is Element {
    return !!element && !!(element as Element).getBoundingClientRect;
}

export function stopPropagation<T>(eventHandler?: React.MouseEventHandler<T>): React.MouseEventHandler<T> {
    return (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        eventHandler && eventHandler(e);
    };
}

export function dataProps<T extends object>(props: T): object {
    return filterObject(props, startsWithData);
}

export function nonDataProps<T extends object>(props: T): object {
    return filterObject(props, key => !startsWithData(key));
}

export function validProps<T extends object>(props: T): object {
    return filterObject(props, key =>
        miscProps.includes(key as string) || startsWithData(key)
    );
}

function startsWithData(key: string | symbol | number) {
    return (key as string).startsWith('data-');
}

const miscProps = [
    'rich-tooltip' // used in BI for tooltips
]

export interface IChildren {
    children: React.ReactNode;
}

export function combineRefs<T>(...refs: (React.Ref<T> | undefined)[]): React.MutableRefObject<T> {
    return {
        get current() { return refs.filter(isRefObject).map(r => r.current)[0]!; },
        set current(value: T) {
            refs.notFalsy().forEach(r => {
                if (isRefObject(r))
                    (r as React.MutableRefObject<T>).current = value;
                else
                    r(value);
            });
        }
    }
}

function isRefObject<T>(ref: React.Ref<T> | undefined): ref is React.RefObject<T> {
    return !!ref && typeof ref == 'object';
}

export interface RefForwardedComponent<P, R> extends React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<R>> {
    /** Use `displayName` instead */
    name: unknown;
    displayName: string;
}

export function forwardRef<R, P = {}>(component: React.RefForwardingComponent<R, P>): RefForwardedComponent<P, R> {
    let forwarded = React.forwardRef(component) as RefForwardedComponent<P, R>;
    if (process.env.NODE_ENV != 'production' && namedFunctionsSupported && !component.name)
        throw new Error("Must use a named function");
    forwarded.displayName = component.name;
    return forwarded;
}

export function useMappedRef<T>(map: (element: Element) => T, inputs: any[] = []) {
    let [value, setValue] = useState<T | undefined>(undefined);
    let ref = useRef(null);
    useMemo(() => setValue(undefined), inputs);

    useLayoutEffect(() => {
        if (ref.current) {
            let element = findDOMNode(ref.current) as Element;
            let mappedValue = map(element);
            if (!shallowEqual(mappedValue, value))
                setValue(mappedValue);
        }
    });

    return [value, ref] as [T | undefined, React.RefObject<Element>];

    function shallowEqual(a: any, b: any) {
        if (typeof a == 'object' && typeof b == 'object') {
            let aKeys = Object.keys(a);
            let bKeys = Object.keys(b);
            return aKeys.length == bKeys.length
                && aKeys.every(key => a[key] === b[key])
        }
        return a === b;
    }
}

export function useMaybeControlledState<T>(defaultValue: T | undefined, value: T | undefined): [T, React.Dispatch<React.SetStateAction<T>>] {
    if (value != undefined)
        return [value, () => { }];

    if (defaultValue != undefined)
        return useState<T>(defaultValue!);

    throw new Error("Must specify either defaultValue or value");
}

var namedFunctionsSupported = !!(function test() { }).name; // For IE