import * as React from "react";
import { useRef, useState } from "react";
import { Column, Row } from "./Box";
import { blackColor, defaultFont, defaultFontColor, halfSpacing, highlightColor, roundedBorders, standardBorder } from "./CommonStyles";
import { bottomDivider } from "./Divider";
import { fadeInAnimation } from "./Flip";
import { SmallHeading } from "./Headings/Headings";
import { isHoverSupported, useHover } from "./Hover";
import { Icon } from "./Icons/Icon";
import { MouseCoords, useMousePosition } from "./Mouse";
import { Alignment, getAnchorContext as createAnchor, IPopupAnchorContext, Popup, Side } from "./Popup";
import { style } from "./styling";
import { useTimeout } from "./Timeout";

export interface ITooltipCommonProps {
    title?: string;
    content?: React.ReactNode;
    helpText?: string;
    helpUrl?: string;
    position?: Side | 'pointer';
    align?: Alignment;
    anchor?: IPopupAnchorContext;
}

export interface ITooltipForExternalElementProps extends ITooltipCommonProps {
    for: React.RefObject<HTMLElement>;
    children?: undefined;
}

export interface ITooltipForChildProps extends ITooltipCommonProps {
    children: React.ReactElement;
}

export function Tooltip(props: ITooltipForExternalElementProps | ITooltipForChildProps) {
    let childRef = useRef<HTMLElement>(null);
    let popupRef = useRef<HTMLDivElement>(null);

    let forRef = 'for' in props ? props.for : childRef;

    let mouse = useMousePosition();
    let mouseAnchor: IPopupAnchorContext = createMouseAnchor(mouse);

    let alignAnchor = props.position == 'pointer' ? mouseAnchor : createAnchor(forRef);
    let positionAnchor = props.anchor || alignAnchor;

    let child = props.children
        ? React.cloneElement(props.children, { ref: childRef, 'data-tooltip': props.content })
        : null;

    let [isHovering, noAnimation] = useHoverScrubbing([forRef, popupRef], 900, 50);

    let side = props.position == 'pointer' ? 'bottom' : props.position;
    let align = props.align || (props.position == 'pointer' ? 'start' : 'center');

    return <>
        {child}
        <Popup anchorAlign={alignAnchor} anchorPosition={positionAnchor} anchorOffset={halfSpacing} show={isHovering && !!props.content} side={side} align={align} minWidth={50} noAnimation={noAnimation} fixed>
            <Column halfSpacing innerPadding ref={popupRef} styles={[tooltip, !noAnimation && fadeInAnimation]}>
                {props.title && <SmallHeading text={props.title} />}
                <div style={{ whiteSpace: typeof props.content == 'string' ? 'pre-line' : undefined }}>{props.content}</div>
                {props.helpUrl &&
                    <>
                        <Row styles={bottomDivider} />
                        <Row>
                            <a href={props.helpUrl} target="_blank">
                                <Row alignItems="center" halfSpacing>
                                    <Icon name="helpOutline" size="tiny" color={highlightColor} />
                                    <span>{props.helpText}</span>
                                </Row>
                            </a>
                        </Row>
                    </>
                }
            </Column>
        </Popup>
    </>;
}

function createMouseAnchor(mouse: MouseCoords): IPopupAnchorContext {
    const offsetToMoveBelowCursor = 18;
    return {
        getRect: () => ({
            left: mouse.current.x,
            right: mouse.current.x,
            top: mouse.current.y + offsetToMoveBelowCursor,
            bottom: mouse.current.y + offsetToMoveBelowCursor,
            width: 0,
            height: 0
        })
    };
}

function useHoverScrubbing(elementRefs: React.RefObject<HTMLElement>[], showDelayMs: number, hideDelayMs: number) {
    if (!isHoverSupported)
        showDelayMs = hideDelayMs = 0;

    let [setTimeout, clearTimeout] = useTimeout();

    let [isHovering, setIsHovering] = useState(false);
    let [immediate, setImmediate] = useState(false);

    useHover(elementRefs,
        () => {
            if (isHovering)
                return clearTimeout(); // Mouse out, then back in before timeout

            if (stopHoveringImmediately) { // Started hovering again before previous tooltip was hidden
                setTimeout(() => {
                    if (stopHoveringImmediately)
                        stopHoveringImmediately();
                    startHovering(true);
                }, hideDelayMs);
            } else {
                setTimeout(() => startHovering(false), showDelayMs); // First hover
            }
        },
        () => {
            if (isHovering)
                setTimeout(stopHovering, hideDelayMs); // Moused out after tooltip shown
            else
                clearTimeout(); // Moused out before tooltip had a chance to be shown
        });

    return [isHovering, immediate];

    function startHovering(immediate: boolean) {
        setIsHovering(true);
        setImmediate(immediate);
        stopHoveringImmediately = stopHovering;
    }

    function stopHovering() {
        setIsHovering(false);
        stopHoveringImmediately = null;
        clearTimeout();
    }
}

let stopHoveringImmediately = null as (() => void) | null;

let tooltip = style('tooltip', {
    ...defaultFont,
    ...defaultFontColor,
    ...roundedBorders,
    border: standardBorder(blackColor),
    background: 'white'
});
