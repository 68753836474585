import { combineRefs, dataProps, forwardRef, nonDataProps, useMaybeControlledState } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useState } from "react";
import { Row } from "./Box";
import { defaultFontFamily, defaultFontSize, disabledBackground, disabledGreyColor, roundedBorders, standardFormControlHeight } from "./CommonStyles";
import { fieldAsRef, IFormFieldProps, useFormOnKeyDown } from "./Form";
import { FormControl } from "./FormControl";
import { IFieldHelperTextProps } from "./HelperText";
import { style, StyleCollection, Styled } from "./styling";
import { IFieldUnderlineProps } from "./Underline";

interface IInputProps<TValue, TType extends string | undefined>
    extends
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'defaultValue'>,
    IFieldUnderlineProps<TValue>,
    IFieldHelperTextProps<TValue>,
    IFormFieldProps<HTMLInputElement> {

    type?: TType;
    value?: TValue;
    defaultValue?: TValue;
    label?: string;
    dirtyValidation?: boolean;
    styles?: StyleCollection;
    templateText?: string;
}

export type TextBoxType = 'tel' | 'url' | 'text' | 'email' | 'search' | 'password';
export interface ITextBoxProps extends IInputProps<string, TextBoxType> { }
export interface ISpinEditProps extends IInputProps<number, never> { }

export const TextBox = forwardRef(function TextBox(props: ITextBoxProps, ref: React.Ref<HTMLInputElement>) {
    return renderTextBox('text', i => i.value, props, ref);
});

export const SpinEdit = forwardRef(function SpinEdit(props: ISpinEditProps, ref: React.Ref<HTMLInputElement>) {
    return renderTextBox('number', i => i.valueAsNumber, props, ref);
});

function renderTextBox<TValue extends string | number, TType extends string | undefined>(defaultType: string, getValue: (input: HTMLInputElement) => TValue, props: IInputProps<TValue, TType>, ref: React.Ref<HTMLInputElement>) {
    let fieldRef = fieldAsRef(props.field);
    let formKeyDown = useFormOnKeyDown();

    let { label, type, value, defaultValue, width, styles, className, dirtyValidation, onBlur, onChange, templateText, placeholder, isFocused, showEmptyHelper,
        isValid = () => undefined,
        isLoading = () => false,
        helperText = () => undefined,
        warningText = () => undefined,
        errorText = () => undefined,
        ...inputProps } = props;
    inputProps = nonDataProps(inputProps);

    let [isDirty, setDirty] = useState(false);
    let [currentValue, setCurrentValue] = useMaybeControlledState(defaultValue, value);

    let ignoreValidation = dirtyValidation && !isDirty;

    let validity = inputProps.disabled || ignoreValidation
        ? undefined
        : isValid(currentValue);

    let hasEmptyValue = currentValue === undefined
        || typeof currentValue == 'string' && !currentValue;

    let isEmpty = hasEmptyValue
        && !props.defaultValue
        && !typeHasDefaultPlaceholder();

    return <Styled.label styles={[textbox, styles]} className={className} inline={{ width }} role="textbox" {...dataProps(props)}>
        <FormControl
            label={label}
            isValid={validity}
            isFocused={isFocused}
            isEmpty={isEmpty}
            isLoading={isLoading(currentValue)}
            disabled={inputProps.disabled}
            showEmptyHelper={showEmptyHelper}
            helperText={helperText(currentValue)}
            warningText={ignoreValidation ? '' : warningText(currentValue)}
            errorText={ignoreValidation ? '' : errorText(currentValue)}>
            {({ getFocusProps, isFocused, inputRef }) =>
                <Row positioned height={standardFormControlHeight}>
                    <Styled.input
                        type={type || defaultType}
                        ref={combineRefs(inputRef, ref, fieldRef)}
                        value={currentValue}
                        styles={input}
                        placeholder={!label || isFocused ? placeholder : undefined}
                        {...inputProps}
                        {...getFocusProps({ onBlur: (e: React.FocusEvent<HTMLInputElement>) => dirtyEventOverride(e, onBlur) })}
                        onChange={changed}
                        onKeyDown={keydown}
                        data-validity={validity} />
                    {!!templateText && (isFocused || !isEmpty || !label)
                        && <Styled.span styles={templateTextStyle}>{templateText}</Styled.span>}
                </Row>}
        </FormControl>
    </Styled.label>;

    function changed(event: React.ChangeEvent<HTMLInputElement>) {
        setCurrentValue(getValue(event.target));
        dirtyEventOverride(event, onChange);
    }

    function keydown(event: React.KeyboardEvent<HTMLInputElement>) {
        dirtyEventOverride(event, formKeyDown);
    }

    function dirtyEventOverride<T extends React.SyntheticEvent<HTMLInputElement>>(event: T, originalHandler?: (event: T) => void) {
        setDirty(true);
        originalHandler && originalHandler(event);
    }

    function typeHasDefaultPlaceholder() {
        switch (props.type) {
            case 'date':
            case 'datetime-local':
            case 'month':
            case 'time':
            case 'week':
                return true;
            default:
                return false;
        }
    }
}

let textbox = style('textbox', {
    display: 'inline-block',
    position: 'relative',
    cursor: 'text'
});

let input = style('textbox-input', {
    ...defaultFontSize,
    ...defaultFontFamily,
    ...roundedBorders,
    border: 'none',
    outline: 'none',
    flex: 1,
    width: 0,
    height: standardFormControlHeight,
    overflow: 'hidden',
    background: 'transparent',
    ':disabled': {
        backgroundColor: disabledBackground
    }
});

let templateTextStyle = style('textbox-template', {
    color: disabledGreyColor,
    height: standardFormControlHeight,
    lineHeight: `${standardFormControlHeight}px`
});
