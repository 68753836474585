import global from "../Client/global";
import { namespace } from "../Client/Namespace.ts";
import "../Scripts/jquery";

if (!history.pushState)
    history.pushState = function () { };
if (!history.replaceState)
    history.replaceState = function () { };

if (!('Type' in global)) {
    global.Type = {
        registerNamespace: namespace
    };
}

Function._validateParams = function () { };

if (!('Sys' in global)) {
    global.Sys = {
        Application: { add_init: function () { } },
        Debug: {
            isDebug: false
        },
        UI: {
            Control: {}
        },
        Component: function () { },
        WebForms: {
            PageRequestManager: function () { }
        },
        Net: {
            WebServiceError: function (timedOut, message, stackTrace, exceptionType, errorObject) {
                this.get_message = function () { return message; };
                this.get_stackTrace = function () { return stackTrace; };
                this.get_exceptionType = function () { return exceptionType; };
                this.get_errorObject = function () { return errorObject; };
            },
            WebServiceProxy: {}
        }
    };
}

(function (oldDispose) {
    Sys.Component.prototype.dispose = function () {
        oldDispose.call(this);
        this._disposed = true;
    };
})(Sys.Component.prototype.dispose);

if (!navigator.cookieEnabled) alert(CommonText.Message_CookiesDisabled);

Element.prototype.matches = Element.prototype.matches || Element.prototype.msMatchesSelector;