export function findScrollParent(target: Element) {
    let current = target as Element | null;
    do {
        if (isScrollElement(current!))
            return current!;
        current = current!.parentElement;
    } while (current);

    return document.documentElement;

    function isScrollElement(element: Element) {
        var style = getComputedStyle(element);
        return style.position == 'fixed'
            || ['auto', 'scroll'].includes(style.overflowX!)
            || ['auto', 'scroll'].includes(style.overflowY!)
            || ['auto', 'scroll'].includes(style.overflow!);
    }
}
