import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { duration, errorColor, greyColor, highlightColor, standardFormControlHeight, successColor, zIndexes } from "./CommonStyles";
import { IFocusHelper, useFocus } from "./FocusHelper";
import { FormLabel, formLabelHeight, formLabelDescenderHeight } from "./FormLabel";
import { HelperText, IHelperTextProps } from "./HelperText";
import { style, Styled, transition } from "./styling";
import { IUnderlineProps, Underline } from "./Underline";

export interface IFormControlProps extends IUnderlineProps, IHelperTextProps {
    label?: string;
    isEmpty: boolean;
    hideUnderline: boolean;
    children(helper: IFocusHelper & { inputRef: React.Ref<HTMLInputElement> }): React.ReactNode;
}

FormControl.defaultProps = {
    isEmpty: true,
    hideUnderline: false
};

export function FormControl(props: IFormControlProps) {
    let inputRef = useRef<HTMLInputElement>(null);
    let [inputValid, setInputValid] = useState(undefined as undefined | boolean);
    let focus = useFocus();
    useEffect(updateInputValid);

    let { label, isEmpty, hideUnderline, children,
        isFocused, isValid, isLoading, disabled,
        ...helperTextProps } = props;

    if (inputValid != null)
        isValid = inputValid && isValid;

    let inlineLabel = !isFocused && !focus.isFocused && isEmpty;

    return <>
        <Styled.div styles={[wrapper, !!label && wrapperWithLabel]} onInput={updateInputValid}>
            <FormLabel
                label={label}
                inline={inlineLabel}
                color={labelColor(disabled, isValid, isFocused || focus.isFocused)}
                styles={[formLabel, inlineLabel ? innerLabel : outerLabel]} />
            {children({ ...focus, isFocused: focus.isFocused || !!isFocused, inputRef })}
            {!hideUnderline &&
                <Underline isFocused={isFocused || focus.isFocused} isValid={isValid} isLoading={isLoading} disabled={disabled} data-testid={testIds.underline} />}
        </Styled.div>
        <HelperText {...helperTextProps} />
    </>;

    function updateInputValid() {
        if (inputRef.current)
            setInputValid(inputRef.current.checkValidity());
    }
}

export let labelSpacing = 1;
let innerLabelShift = formLabelHeight + (standardFormControlHeight - formLabelHeight) / 2 - formLabelDescenderHeight + labelSpacing; // Should move label into same position as text in standard form control
export let outerLabelSpace = 20;

let formLabel = style('formControl-label', {
    transition: transition(['transform', 'color', 'font-size'], duration.small),
    position: 'absolute',
    top: outerLabelSpace - formLabelHeight - labelSpacing,
    zIndex: zIndexes.abovePage,
});

let outerLabel = style('is-formControl-label-outer', {
    transform: 'none',
});

let innerLabel = style('is-formControl-label-inner', {
    transform: { translateY: innerLabelShift },
});

let wrapper = style('formControl-wrapper', {
    position: 'relative'
});

let wrapperWithLabel = style('formControl-wrapperWithLabel', {
    paddingTop: outerLabelSpace
});

function labelColor(disabled: boolean | undefined, isValid: boolean | undefined, isFocused: boolean) {
    return disabled
        ? greyColor
        : validityColor(isValid) || focusedColor(isFocused);
}

function validityColor(isValid?: boolean) {
    return isValid
        ? successColor
        : isValid === false
            ? errorColor
            : undefined;
}

function focusedColor(isFocused: boolean) {
    return isFocused
        ? highlightColor
        : greyColor;
}

export const testIds = {
    underline: 'underline'
}
