﻿import { applyCommonGlobalStyles } from "@zap/ui/lib/GlobalStyles";
import { setState, State } from "event-reduce/lib/experimental/state";
import * as mobx from "mobx";
import { observable } from "mobx";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { updateStylesheet } from "stylemap";
import global from "../../../../SharedSource/Web.Core/Client/global";
import { ILoginFormProps, LoginForm } from "./LoginForm";
import { LoginModel } from "./LoginModel";
let remotedev = require('mobx-remotedev');

/** Wraps the root model since mobx-remotedev doesn't allow replacing the global store */
class ModelWrapper {
    @observable
    login = new LoginModel();

    importState(state: State<ModelWrapper>) {
        setState(this, state);
    }
}

let model = new ModelWrapper();

applyCommonGlobalStyles();
updateStylesheet();
configureReduxDevTools();

(global as any).boot = (rootElement: HTMLElement, props: ILoginFormProps) =>
    ReactDOM.render(<LoginForm model={model.login} {...props} />, rootElement);

function configureReduxDevTools() {
    (mobx as any).extras = mobx; // Fixes mobx-remotedev compatibility with mobx 4
    remotedev(model, { name: 'root', global: true });
}