import { filterObject } from "@zap/utils/lib/Object";
import { forwardRef, IChildren, validProps } from '@zap/utils/lib/ReactHelpers';
import * as CSS from "csstype";
import * as React from 'react';
import { useContext } from "react";
import { standardSpacing, halfSpacing } from "./CommonStyles";
import { style, StyleCollection, Styled, TLength } from './styling';

export interface IBoxStyleProps {
    flex?: string;
    grow?: boolean;
    shrink?: boolean;
    flexBasis?: React.CSSProperties['flexBasis'];
    order?: number;
    inline?: boolean;
    column?: boolean;
    reverse?: boolean;
    wrap?: React.CSSProperties['flexWrap'];
    alignContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between';
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between';
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
    alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
    fit?: boolean;
    center?: boolean;
    width?: TLength;
    height?: TLength;
    minWidth?: TLength;
    minHeight?: TLength;
    maxWidth?: TLength;
    maxHeight?: TLength;
    noSpacing?: boolean;
    halfSpacing?: boolean;
    innerPadding?: boolean;
    className?: string;
    styles?: StyleCollection;
    inlineStyles?: React.CSSProperties;
    scrollX?: boolean;
    scrollY?: boolean;
    positioned?: boolean;
    role?: string;
}

export interface IBoxProps extends IBoxStyleProps, Partial<IChildren> { }

const BoxDirection = React.createContext('inherit' as CSS.FlexDirectionProperty);

export let Box = forwardRef(function Box(props: IBoxProps, ref: React.Ref<HTMLDivElement>) { return renderBox(props, ref); });
export let Row = forwardRef(function Row(props: IBoxProps, ref: React.Ref<HTMLDivElement>) { return renderBox({ ...props, column: false }, ref); });
export let Column = forwardRef(function Column(props: IBoxProps, ref: React.Ref<HTMLDivElement>) { return renderBox({ ...props, column: true }, ref); });
export let Center = forwardRef(function Center(props: IBoxProps, ref: React.Ref<HTMLDivElement>) { return renderBox({ ...props, center: true }, ref); });
export function Spacer() { return <Styled.div styles={spacerStyle}></Styled.div>; }

function renderBox(props: IBoxProps, ref: React.Ref<HTMLDivElement>) {
    let parentDirection = useContext(BoxDirection);

    let boxStyle = boxStyles(props, parentDirection);

    let direction = boxStyle.flexDirection!.startsWith('column') ? column : row;

    let spacing = props.noSpacing ? undefined
        : props.halfSpacing ? halfSpacedItems
            : fullSpacedItems;

    return <Styled.div ref={ref} styles={[direction, spacing, props.styles]} inline={{ ...boxStyle, ...(props.inlineStyles || {}) }} className={props.className} role={props.role} {...validProps(props)}>
        <BoxDirection.Provider value={boxStyle.flexDirection!}>
            {props.children}
        </BoxDirection.Provider>
    </Styled.div>;
};

export function boxStyles(props: IBoxStyleProps, parentDirection: CSS.FlexDirectionProperty = 'inherit'): React.CSSProperties {
    let shrink = props.shrink != null ? props.shrink
        : parentDirection == 'column' && props.scrollY ? true
            : parentDirection == 'row' && props.scrollX ? true
                : undefined;

    let flexDirection = props.column == null ? 'inherit'
        : props.column ? 'column' : 'row'
            + (props.reverse ? '-reverse' : '') as CSS.FlexDirectionProperty;

    return filterObject({
        display: props.inline ? 'inline-flex' : 'flex',
        flex: props.flex == null ? undefined : props.flex.toString(),
        flexGrow: props.grow == null ? undefined
            : props.grow ? 1
                : 0,
        flexShrink: shrink == null ? undefined
            : shrink ? 1
                : 0,
        flexBasis: props.flexBasis,
        order: props.order,
        flexWrap: props.wrap,
        flexDirection: flexDirection,
        alignContent: props.alignContent,
        justifyContent: props.justifyContent || (props.center ? 'center' : '') as React.CSSProperties['justifyContent'],
        alignItems: props.alignItems || (props.center ? 'center' : '') as React.CSSProperties['alignItems'],
        alignSelf: props.alignSelf,
        width: props.width != null ? props.width
            : props.fit ? '100%'
                : undefined,
        height: props.height != null ? props.height
            : props.fit ? '100%'
                : undefined,
        minWidth: props.minWidth,
        minHeight: props.minHeight,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        overflowX: props.scrollX ? 'auto'
            : props.shrink ? 'hidden'
                : undefined as React.CSSProperties['overflowX'],
        overflowY: props.scrollY ? 'auto'
            : props.shrink ? 'hidden'
                : undefined as React.CSSProperties['overflowY'],
        position: props.scrollX || props.scrollY || props.positioned ? 'relative' : undefined as React.CSSProperties['position'],
        padding: props.innerPadding
            ? props.halfSpacing
                ? halfSpacing
                : standardSpacing
            : undefined
    }, (_, val) => val != null);
}

let spacerStyle = style('spacer', { flex: 1 });

export let noSpacing = style('boxItem-noSpacing', {});

let row = style('row', {});
let column = style('column', {});

let fullSpacedItems = style('box-spacing', {
    $: {
        [`&.${column} > *:not(.${noSpacing}) ~ *:not(.${noSpacing})`]: { marginTop: standardSpacing },
        [`&.${row} > *:not(.${noSpacing}) ~ *:not(.${noSpacing})`]: { marginLeft: standardSpacing }
    }
});

let halfSpacedItems = style('box-halfSpacing', {
    $: {
        [`&.${column} > *:not(.${noSpacing}) ~ *:not(.${noSpacing})`]: { marginTop: halfSpacing },
        [`&.${row} > *:not(.${noSpacing}) ~ *:not(.${noSpacing})`]: { marginLeft: halfSpacing }
    }
});

export let columnItemSpacing = style('column-itemSpacing', {
    $: { '& + &': { marginTop: standardSpacing } }
});

export let rowItemSpacing = style('row-itemSpacing', {
    $: { '& + &': { marginLeft: standardSpacing } }
});
