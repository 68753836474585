import * as React from "react";
import { Flipper as InnerFlipper, FlipperProps, HandleEnterUpdateDeleteArgs } from "react-flip-toolkit";
import { duration } from "./CommonStyles";
import { classes, style, animation } from "stylemap";
export * from "react-flip-toolkit";

export let Flipper = InnerFlipper;

function NullFlipper(props: FlipperProps) {
    props = { ...props, flipKey: null };
    return <InnerFlipper {...props} />;
}

// Expose a hook for tests to disable flip animations
(document as any).disableFlip = () => Flipper = NullFlipper;



export function delayEntry(step: HandleEnterUpdateDeleteArgs) {
    step.hideEnteringElements();
    step.animateExitingElements();
    step.animateFlippedElements();
    setTimeout(() => step.animateEnteringElements(), duration.small / 2);
}

export function fadeIn(element: HTMLElement) {
    element.style.opacity = '';
    element.className += " " + classes(fadeInAnimation);
}

export function fadeOut(element: HTMLElement, index: number, removeElement: () => void) {
    element.className += " " + classes(fadeOutAnimation);
    setTimeout(removeElement, duration.small);
}

export let fadeInAnimation = style('fadeIn', {
    animation: {
        keyframes: {
            from: { opacity: 0 },
            to: { opacity: 1 }
        },
        animationDuration: duration.small
    }
});

export let fadeOutAnimation = style('fadeOut', {
    animation: animation({
        from: { opacity: 1 },
        to: { opacity: 0 }
    }, duration.small, 'ease')
});