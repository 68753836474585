/// <reference path="../../../../SharedSource/Web.Core/Client/typings/ZapVariables.d.ts" />
/// <reference path="../typings/UserController.d.ts" />
import { useScrollTo } from "@zap/ui/lib/AutoScroll";
import { Center, Column, Row } from "@zap/ui/lib/Box";
import { Button } from "@zap/ui/lib/Button";
import { disabledGreyColor, largeHeadingFontSize, roundedBorders, whiteColor } from "@zap/ui/lib/CommonStyles";
import { defaultFormWidth, Form } from "@zap/ui/lib/Form";
import { HelperText } from "@zap/ui/lib/HelperText";
import { Icon } from "@zap/ui/lib/Icons/Icon";
import { Side, usePopupAnchor } from "@zap/ui/lib/Popup";
import { Elevation, shadow } from "@zap/ui/lib/Shadow";
import { Styled } from "@zap/ui/lib/styling";
import { Switch } from "@zap/ui/lib/Switch";
import { TextBox } from "@zap/ui/lib/TextBox";
import { useObserver } from "mobx-react-lite";
import * as React from "react";
import { style } from "stylemap";
import "../../../../SharedSource/Web.Core/Client/Text";
import { Tooltip } from "../../../../SharedSource/Web.Core/Client/Tooltip";
import "../../../../SharedSource/Web.Core/Scripts/extensions";
import "../../../../SharedSource/Web.Core/Scripts/Tooltips";
import { antiForgeryHeaders } from "../AntiForgery";
import { LoginModel } from "./LoginModel";

export interface ILoginFormProps {
    model: LoginModel;
    authUrl: string;
    challengeUrl: string;
    returnUrl: string;
    headerBackgroundColor: string;
    headerImage: string;
}

export function LoginForm(props: ILoginFormProps) {
    let model = props.model;
    let events = model.events;

    return useObserver(() => {
        let [containerRef, containerAnchor] = usePopupAnchor<HTMLDivElement>();
        let scroll = useScrollTo<HTMLButtonElement>(model.loggingIn.isLoading);

        let wideEnoughToShowTooltipsOnTheSide = window.innerWidth >= 1024;
        let tooltipAnchor = wideEnoughToShowTooltipsOnTheSide ? containerAnchor : undefined;
        let tooltipSide: Side = wideEnoughToShowTooltipsOnTheSide ? 'right' : 'bottom';

        return <Center fit>
            <Column styles={containerStyles} ref={containerRef}>
                <Form onSubmit={() => login()}>
                    <Column center innerPadding styles={header} inlineStyles={{ background: props.headerBackgroundColor }}>
                        <Styled.img src={props.headerImage} styles={logo} />
                        <Row maxWidth="90%">{PageText.Login_Title.template([ZapVariables.AppInfo.ApplicationName])}</Row>
                    </Column>
                    <Column innerPadding>
                        <Tooltip definition={Zap.BI.Tooltips.Login.LoginUsername} position={tooltipSide} anchor={tooltipAnchor}>
                            <TextBox
                                label={PageText.Login_UserName}
                                name="username"
                                autoCapitalize="none"
                                value={model.username}
                                onChange={e => events.usernameChanged(e.target.value)}
                                isValid={() => model.usernameValidity}
                                errorText={() => model.usernameError}
                                showEmptyHelper
                                dirtyValidation={!model.loginAttempted}
                            />
                        </Tooltip>
                        <Tooltip definition={Zap.BI.Tooltips.Login.LoginPassword} position={tooltipSide} anchor={tooltipAnchor}>
                            <TextBox
                                label={PageText.Login_Password}
                                name="password"
                                type="password"
                                value={model.password}
                                onChange={e => events.passwordChanged(e.target.value)}
                                isValid={() => model.passwordValidity}
                                errorText={() => model.passwordError}
                                showEmptyHelper
                                dirtyValidation={!model.loginAttempted}
                            />
                        </Tooltip>
                        <Tooltip definition={Zap.BI.Tooltips.Login.LoginRememberMe} position={tooltipSide} anchor={tooltipAnchor}>
                            <Switch label={PageText.Login_RememberMe} checked={model.rememberMe} onChange={e => events.rememberMeChanged({ enabled: e.target.checked })} />
                        </Tooltip>

                        <Column center>
                            <HelperText errorText={model.loginError} />
                            <Button type="submit" ref={scroll} outlined onClick={() => login()} isLoading={model.loggingIn.isLoading} disabled={model.hasChallenged}>{PageText.Login_Button}</Button>
                            <Tooltip definition={Zap.BI.Tooltips.Login.LoginWindowsChallenge} position={tooltipSide} anchor={tooltipAnchor}>
                                <Button grey contained onClick={() => challenge()} isLoading={model.hasChallenged} disabled={model.loggingIn.isLoading} styles={iconButton}>
                                    <Row center halfSpacing>
                                        <Icon name="windows" color={model.hasChallenged || model.loggingIn.isLoading ? disabledGreyColor : whiteColor} />
                                        <span>{PageText.Login_WindowsChallenge}</span>
                                    </Row>
                                </Button>
                            </Tooltip>
                        </Column>
                    </Column>
                </Form>
            </Column>
        </Center>
    });

    function login() {
        if (model.username && model.password) {
            let authRequest: server.AuthenticationRequest = {
                Username: model.username,
                Password: model.password,
                IsPersistent: model.rememberMe
            };

            let loggedIn = fetch(props.authUrl, {
                method: 'POST',
                headers: [['content-type', 'application/json'], ['Accept', 'application/json'], ...Object.entries(antiForgeryHeaders())],
                body: JSON.stringify(authRequest)
            }).then(response => {
                if (!response.ok)
                    return response.json().then<void>(e => Promise.reject(e));
            });
            events.authenticated(loggedIn);
            loggedIn.then(() => location.href = props.returnUrl);
        } else {
            events.authenticated(Promise.reject({}));
        }
    }

    function challenge() {
        events.challenged();
        location.pathname = props.challengeUrl;
    }
}

let containerStyles = style('container', {
    ...roundedBorders,
    ...shadow(Elevation.Card),
    background: 'white',
    width: defaultFormWidth,
    $: {
        [`@media(max-width: ${defaultFormWidth}px)`]: {
            width: '100vw'
        }
    }
});

let header = style('header', {
    ...largeHeadingFontSize,
    fontWeight: 'lighter',
    textAlign: 'center',
    color: whiteColor
});

let logo = style('logo', {
    height: 48
});

let iconButton = style('iconButton', {
    height: 40
});
