import { border, style } from "./styling";

export let highlightColor = '#217346';
export let lightHighlightColor = '#cfe8cf';
export let errorColor = '#e45847';
export let successColor = '#55ab55';
export let warningColor = '#ffa500';
export let blackColor = '#111111';
export let greyColor = '#555';
export let whiteColor = '#fcfcfc';
export let darkGreyColor = '#333';
export let lightGreyColor = '#e6e6e6';
export let blueColor = '#35bbe8';
export let hoverColor = '#e3e3e3';
export let linkColor = '#39d3b7';

export let duration = {
    /** Small movements, like a switch */
    small: 100,
    /** Things appearing out of nowhere, like a menu */
    appear: 120,
    /** Larger movements */
    medium: 250,
    /** Sliding in from the edge */
    slideIn: 250,
    /** Sliding out to the edge */
    slideOut: 200,
};

export let standardBorderColor = lightGreyColor;
export let standardBorderWidth = 1;
export let standardBorderStyle = 'solid';
export let standardBorder = (color = standardBorderColor) => border(standardBorderWidth, standardBorderStyle, color);
export let roundedBorderRadius = 2;
export let roundedBorders = style('roundedBorders', { borderRadius: roundedBorderRadius });

export let disabledGreyColor = '#808080';
export let disabledBackground = '#ebebe4';

export let interactiveOpacity = 0.56;
export let hoverOpacity = 0.7;
export let disabledOpacity = 0.26;

export let standardSpacing = 16;
export let halfSpacing = standardSpacing / 2;
export let quarterSpacing = standardSpacing / 4;
export let standardFormControlHeight = 36;

export let fontSizes = {
    label: 11,
    default: 12,
    headingSmall: 15,
    headingLarge: 18
}

export let defaultFontColor = style('defaultFontColor', { color: blackColor });
export let lightFontColor = style('lightFontColor', { color: greyColor });

export let labelFontSize = style('labelFontSize', { fontSize: fontSizes.label });
export let defaultFontSize = style('defaultFontSize', { fontSize: fontSizes.default });
export let smallHeadingFontSize = style('smallHeadingFontSize', { fontSize: fontSizes.headingSmall });
export let largeHeadingFontSize = style('largeHeadingFontSize', { fontSize: fontSizes.headingLarge });

export let lineHeight = 1.2;

export let defaultFontFamily = style('defaultFontFamily', { fontFamily: '"Open Sans", "Segoe UI", Helvetica, Tahoma, sans-serif' });

export let defaultFont = style('defaultFont', { ...defaultFontFamily, ...defaultFontSize });
export let labelFont = style('labelFont', { ...defaultFontFamily, ...labelFontSize });

export let inlineBlock = style('inlineBlock', { display: 'inline-block' });

export let pageContentWidth = '1200px';

export let truncateText = style('truncateText', {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

export let hideIfEmpty = style('hideIfEmpty', {
    ':empty': { display: 'none' }
});

export let zIndexes = {
    belowPage: -1,
    page: 0,
    abovePage: 1,
    popup: 2,
    loadingOverlay: 3,
    notification: 4
}