import "../Scripts/compatibility";
import global from "../Client/global.ts";

Type.registerNamespace("Zap.UI.OptionItems");

Zap.sizes = ['large', 'small', 'very-small'];

Zap.scrollBarWidth = 18;

Zap.initInstanceMethods = function Zap$initInstanceMethods(obj, context) {
    /// <summary>
    /// Creates a delegate for every function in the given object starting with '$' that
    /// keeps the context provided, with the '$' removed. The context defines the object instance to which the
    /// 'this' keyword points.
    /// Eg. obj with $myFunc function will be given a myFunc function that will apply $myFunc with this == context.
    /// </summary>
    var methodKey;
    var undef;
    if (context === undef) { context = obj; }
    for (methodKey in obj) {
        if ($.isFunction(obj[methodKey]) && methodKey.substr(0, 1) === '$' && !obj[methodKey.substr(1)]) {
            obj[methodKey.substr(1)] = createDisposableDelegate(context, obj[methodKey]);
            obj[methodKey.substr(1)].isInstanceMethod = true;
            if (Sys.Debug.isDebug) {
                // Helpful for debugging instance methods bound to jQuery events
                obj[methodKey.substr(1)].originalName = methodKey;
                obj[methodKey.substr(1)].originalCode = obj[methodKey].toString();
            }
        }
    }

    //FIXME: temporary fix for returning webservice call on disposed objects. Ideally should remove all pending webservice calls when the object is disposed.
    function createDisposableDelegate(instance, method) {
        return function () {
            return !instance || instance._disposed === true ?
                undefined : method.apply(instance, arguments);
        };
    };
};

Zap.disposeInstanceMethods = function Zap$disposeInstanceMethods(obj) {
    /// <summary>Nulls all functions marked as isInstanceMethod in the given object</summary>
    var func;
    for (func in obj) {
        if (obj.constructor.prototype.hasOwnProperty(func) && $.isFunction(obj[func]) && obj[func].isInstanceMethod) {
            delete obj[func];
        }
    }
};

Zap.NotImplementedError = function (message) {
    this.message = message;
    this.stack = new Error().stack;
};
Zap.abstractMethod = function Zap_abstractMethod() {
    throw new Zap.NotImplementedError("Tried to execute an abstract method.");
};
Zap.abstractMethod.args = function (argNames) {
    return new Function(argNames, 'throw new Zap.NotImplementedError("Tried to execute an abstract method.");');
};

Zap.callback = {
    add: function () {
        throw new Error("Callback instance hasn't been set");
    },

    fire: function () {
        throw new Error("Callback instance hasn't been set");
    }
};

Zap.nullCallback = {
    add: function () {
    },

    fire: function () {
    }
};

Zap.treePreOrder = function Zap_treePreOrder(node, getChildren) {
    return Zap.filteredTreePreOrder(node, getChildren, Zap.returnTrue);
};

Zap.filteredTreePreOrder = function Zap_filteredTreePreOrder(node, getChildren, predicate) {
    var parent, children;
    if (Array.isArray(node)) {
        parent = [];
        children = node;
    } else {
        parent = [node];
        children = getChildren(node);
    }

    if (!children) return parent;

    var filteredChildren = children
        .filter(predicate)
        .map(function (child) {
            return Zap.filteredTreePreOrder(child, getChildren, predicate);
        });

    if (filteredChildren.length === 0 && parent[0]) {
        return predicate(parent[0])
            ? parent
            : [];
    }

    return Array.prototype.concat.apply(parent, filteredChildren);
};

Zap.emptyFunction = function () { };
Zap.yield = function (x) { return x; };
Zap.returnTrue = function Zap_returnTrue() { return true; };
Zap.returnFalse = function Zap_returnFalse() { return false; };

Zap.bindEventMappings = function Zap_bindEvents(control, mappings) {
    var undef;
    if (!mappings) return;

    var eachEvent = function (eventMapping) {
        var functionName = eventMapping.Function;
        var data = eventMapping.Data;

        var eachEventItem = function (clientEventName) {
            if (Sys.Debug.isDebug && !control[functionName]) {
                // We're here because we tried to bind a client event name to a function that does not exist. -sleigh
                debugger;
            }
            if (data === null || data === undef) {
                $(document).on(clientEventName, control[functionName]);
            } else {
                $(document).on(clientEventName, data, control[functionName]);
            }
        };

        eventMapping.ClientEventList.forEach(eachEventItem);
    };

    mappings.forEach(eachEvent);
};

Zap.unbindEventMappings = function (mappings) {
    if (!mappings) return;

    mappings.forEach(function (eventMapping) {
        eventMapping.ClientEventList.forEach(unbindEvent);
    });

    function unbindEvent(eventName) {
        $(document).off(eventName);
    }
};

Zap.createEnum = function (values) {
    /// <summary>
    /// Creates an enum class that can be specified as a method return type.
    /// </summary>
    var enumConstructor = function () { };
    $.extend(enumConstructor, values, Zap.enumPrototype);
    enumConstructor._values = values;

    return enumConstructor;
};

window.WebServiceFailedCallback = function (element) {
    return TriggerErrorEvent.bind(element);
};

window.TriggerErrorEvent = function (response) {
    var exceptionType = response.Type || (response.get_exceptionType && response.get_exceptionType());
    if (exceptionType === "Zap.BI.Portal.Controls.StatePocketDoesNotExistException"
        || exceptionType === "Zap.BI.Portal.Helpers.SessionNotInitializedException") {
        // Force a heartbeat to check whether the session was replaced
        window.GlobalHeartbeat.Beat(true);
        return;
    }

    // Trigger the showing of any error message, and hide any loading overlay or progress indicator that might be present
    $(this).trigger('ErrorEvent', response).trigger('ShowProgress', false).loadingOverlay('hide');
};

window.ReportServerSideError = function (response) {
    if (response && response._message && response._stackTrace)
        ReportError(response._message, response._stackTrace);
}

window.ReportError = function (message, stackTrace) {
    if (Sys.Debug.isDebug) {
        debugger;
    }

    ReportErrorQuietly(message, stackTrace);
}

window.ReportErrorQuietly = function (message, stackTrace) {
    try {
        Zap.BI.Portal.JavaScriptMetrics.ReportError(message, stackTrace, Zap.BI.EventHandlerHelpers.GlobalErrorHandlerCallback, ReportErrorFailed, message, stackTrace);
    } catch (exc) {
        ReportErrorFailed(null, message);
    }
}

window.ReportErrorFailed = function (response, message, stackTrace) {
    // display an error ribbon if we're in debug mode, otherwise just be quiet about it.
    if (Sys.Debug.isDebug) {
        var details = CommonText.Label_Error + ": " + message + '\n' + stackTrace;
        if (response && response._message) { details += "\n\n" + CommonText.Label_ServerResponse + ": " + response._message; }
        ErrorRibbon.DisplayMessage(CommonText.Message_ErrorReportingToServer, details);
    }
}

window.EvalWithReturnValue = function (scriptToEval) {
    try {
        return new Function(scriptToEval)();
    } catch (exc) {
        ReportError(Sys.Debug.extractExceptionMessage(exc), Sys.Debug.extractStackTrace(exc));
    }

    return undefined;
}

Zap.setEventHandled = function () {
    Zap.eventHandled = true;
    setTimeout(function () { Zap.eventHandled = false; }, 0);
};

Zap.hasEventBeenHandled = function () {
    return Zap.eventHandled;
};

Zap.nbsp = '\u00A0\u00A0';

Zap.resolveType = function (typeName) {
    return (typeName || '')
        .split('.')
        .reduce(function (parent, prop) { return parent[prop]; }, global);
};

Zap.resolveTypeSafe = function (typeName) {
    return (typeName || '')
        .split('.')
        .reduce(function (parent, prop) { return parent && parent[prop]; }, global);
};
