import * as React from "react";
import { useContext, useRef, useState } from "react";
import { Column, Row } from "./Box";

export interface IFormProps {
    children: React.ReactNode;
    onSubmit?(): void;
    onCancel?(): void;
    onReset?(): void;
}

export interface IFormHelper {
    submit(): void;
    cancel(): void;
    reset(): void;
}

export interface IVerticalFormProps extends IFormProps {
    grow?: boolean;
}

export function VerticalForm(props: IVerticalFormProps) {
    let { children, grow, ...formProps } = props;
    return <Form {...formProps}>
        <Column grow={grow} width={grow ? undefined : defaultFormWidth} alignItems="stretch">
            {children}
        </Column>
    </Form>;
}

export const defaultFormWidth = 400;

export function HorizontalForm(props: IFormProps) {
    let { children, ...formProps } = props;
    return <Form {...formProps}>
        <Row alignItems="flex-end">
            {children}
        </Row>
    </Form>;
}

/** Groups a set of fields that are submitted/cancelled/cleared together. */
export function Form(props: IFormProps) {
    let formHelper = useRef<IFormHelper>({ submit, cancel, reset });
    let [revision, setRevision] = useState(0);

    return <FormContext.Provider key={'rev' + revision} value={formHelper.current}>
        {props.children}
    </FormContext.Provider>;

    function submit() {
        if (props.onSubmit)
            props.onSubmit();
    }

    function cancel() {
        if (props.onCancel)
            props.onCancel();
        clearForm();
    }

    function reset() {
        if (props.onReset)
            props.onReset();
        clearForm();
    }

    function clearForm() {
        setRevision(r => r + 1); // Resets form controls to default values
    }
}

let FormContext = React.createContext<IFormHelper>({ submit() { }, cancel() { }, reset() { } });

/** Provides a keydown handler that submits form on enter and cancels form on escape. */
export function FormOnKeyDown(props: { children(onKeyPress: (event: React.KeyboardEvent) => void): React.ReactNode }) {
    let onKeyPress = useFormOnKeyDown();
    return <>{props.children(onKeyPress)}</>;
}

/** Provides a keydown handler that submits form on enter and cancels form on escape. */
export function useFormOnKeyDown() {
    let form = useFormContext();
    return (event: React.KeyboardEvent) => {
        if (event.key == 'Enter')
            form.submit();
        else if (event.key == 'Escape')
            form.cancel();
    };
}

/** Provides a click handler that calls the appropriate form action based on the type of button. */
export function useFormButtonClick(type: string | undefined, onClick?: () => void) {
    let form = useFormContext();

    return () => {
        if (type == 'submit')
            form.submit();
        else if (type == 'cancel')
            form.cancel();
        else if (type == 'reset')
            form.reset();

        if (onClick)
            onClick();
    }
}

export function useFormContext() {
    return useContext(FormContext);
}

export interface IFieldRef<T extends {}> {
    field: T;
}

type FieldType<T extends ComponentType<any>> = PropsType<T> extends IFormFieldProps<infer TField> ? TField : never;
type PropsType<T extends ComponentType<any>> = T extends ComponentType<infer P> ? P : never;
type ComponentType<T> = React.ComponentType<T> | React.Component<T>;

/** Provides a box for storing a form field value. */
export function useField<T extends ComponentType<any>>(componentType?: T): IFieldRef<FieldType<T>> {
    let ref = useRef<FieldType<T>>(null!);
    return {
        get field() { return ref.current || {} as FieldType<T>; },
        set field(value: FieldType<T>) { ref.current = value; }
    };
}

/** Converts a field to a ref function. Will just be a noop if field is undefined. */
export function fieldAsRef<T>(fieldRef?: IFieldRef<T>) {
    return (field: T) => {
        if (fieldRef)
            fieldRef.field = field;
    };
}

export interface IFormFieldProps<T extends {}> {
    field?: IFieldRef<T>
}