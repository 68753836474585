import * as React from "react";
import { defaultFont, defaultFontColor, fontSizes, lightFontColor, labelFont } from "./CommonStyles";
import { standardLabelStyle, defaultLabelHeight, defaultDescenderHeight } from "./StandardLabel";
import { style, StyleCollection, Styled } from "./styling";

export interface IFormLabelProps {
    label?: string;
    styles?: StyleCollection;
    inline?: boolean;
    color?: string;
}

export function FormLabel({ label, styles, inline, color, ...rest }: IFormLabelProps & React.HTMLAttributes<HTMLElement>) {
    let layout = inline ? inlineLabel : outerLabel;
    color = color
        || (inline
            ? defaultFontColor.color
            : lightFontColor.color) as string;

    return label
        ? <Styled.div
            data-testid={testIds.text}
            styles={[common, layout, styles]}
            inline={{ color }}
            {...rest}>
            {label}
        </Styled.div>
        : null;
}

let common = style('formLabel', {
    userSelect: 'none',
    pointerEvents: 'none',
});

export let formLabelHeight = defaultLabelHeight;
export let formLabelDescenderHeight = defaultDescenderHeight;

let outerLabel = style('is-formLabel-outer', {
    ...labelFont,
    ...standardLabelStyle(fontSizes.label, formLabelHeight, formLabelDescenderHeight)
});

let inlineLabel = style('is-formLabel-inline', {
    ...defaultFont,
    ...standardLabelStyle(fontSizes.default, formLabelHeight, formLabelDescenderHeight),
    display: 'inline-block'
});

export const testIds = {
    text: "form_label_text"
}
