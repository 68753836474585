import React = require("react");
import { combineRefs, forwardRef, stopPropagation } from "@zap/utils/lib/ReactHelpers";
import { Uuid } from "@zap/utils/lib/Uuid";
import { memo, useRef, useState } from "react";
import { StyleCollection } from "stylemap";
import { Hsla } from "./color";
import { darkGreyColor, disabledBackground, disabledGreyColor, duration, errorColor, greyColor, highlightColor, roundedBorders, whiteColor, zIndexes } from "./CommonStyles";
import { FocusHelper, IFocusHelper } from "./FocusHelper";
import { Styled } from "./StyledComponent";
import { animation, style, Styles, transition } from "./styling";

export interface IClickableProps {
    round?: boolean;
    color?: keyof typeof colorStyles;
    invert?: boolean;
    disabled?: boolean;
    children(helper: IFocusHelper): React.ReactNode;
    hideRipple?: boolean;
    onClick?: () => void;
    selected?: boolean;
    styles?: StyleCollection;
    tabIndex?: number;
}

export const Clickable = forwardRef(function Clickable(
    {
        round = false,
        color = 'default',
        invert = false,
        disabled = false,
        hideRipple = false,
        selected = false,
        onClick, styles, tabIndex, children
    }: IClickableProps,
    ref: React.Ref<HTMLDivElement>) {

    let wrapperRef = useRef<HTMLDivElement>(null);
    let [ripples, setRipples] = useState([] as JSX.Element[]);

    let colored = getColorStyles();
    return <FocusHelper disabled={disabled} ref={ref}>
        {(helper, forwardingRef) =>
            <Styled.div ref={combineRefs(forwardingRef, wrapperRef)} data-testid={testIds.clickable} tabIndex={tabIndex}
                styles={[
                    clickableStyle,
                    disabled ? colored.disabled : colored.enabled,
                    round ? circle : square,
                    selected && colored.selected,
                    helper.isFocused && colored.focused,
                    hideRipple && !disabled && colored.hiddenRipple,
                    styles
                ]}
                onClick={stopPropagation(e => onClickOverride(e))}>
                {children(helper)}
                {ripples}
            </Styled.div>
        }
    </FocusHelper>;

    function onClickOverride(e: React.MouseEvent<HTMLDivElement>) {
        if (!disabled) {
            if (!hideRipple)
                addRipple(e);
            if (onClick)
                onClick();
        }
    }

    function addRipple(e: React.MouseEvent<HTMLDivElement>) {
        let key = Uuid.create();
        let ripple = <Ripple key={key} {...getRippleProps(e, key)} />;
        setRipples(rs => rs.concat(ripple));
    }

    function getRippleProps(e: React.MouseEvent<HTMLDivElement>, key: string): IRippleProps {
        let el = wrapperRef.current!;
        let rect = el.getBoundingClientRect();
        let size = Math.max(el.clientWidth, el.clientHeight);
        let x = e.clientX || rect.left + rect.width / 2;
        let y = e.clientY || rect.top + rect.height / 2;

        return {
            left: x - rect.left - size / 2,
            top: y - rect.top - size / 2,
            color: getColorStyles().color,
            size,
            animationDone: () => removeRipple(key)
        }
    }

    function removeRipple(key: string) {
        setRipples(rs => rs.filter(r => r.key !== key));
    }

    function getColorStyles() {
        return invert
            ? invertedColorStyles[color]
            : colorStyles[color];
    }
});

const hoverOpacity = 0.06;
const focusOpacity = 0.12;
const activeOpacity = 0.2;

let clickableStyle = style('clickable', {
    display: 'inline-block',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    WebkitTapHighlightColor: 'transparent', // prevent touch flash in chrome
    zIndex: zIndexes.page,
    transition: transition('background', duration.small),
    ':focus': {
        outline: 'none'
    }
});

let colorStyles = {
    default: backgroundStyles('default', highlightColor, false),
    dangerous: backgroundStyles('dangerous', errorColor, false),
    grey: backgroundStyles('grey', greyColor, false),
    darkGrey: backgroundStyles('darkGrey', darkGreyColor, false)
};

let invertedColorStyles = {
    default: backgroundStyles('default', highlightColor, true),
    dangerous: backgroundStyles('dangerous', errorColor, true),
    grey: backgroundStyles('grey', greyColor, true),
    darkGrey: backgroundStyles('darkGrey', darkGreyColor, true)
};

function backgroundStyles(name: string, color: string, invert = false) {
    let hsl = Hsla.parse(color)!;

    let foreground = invert ? whiteColor : color;

    let fade = (opacity: number) => hsl.fade(invert ? 0.9 - opacity : opacity).toString();

    let enabled = style(`clickable-${name}-enabled`, {
        background: invert ? color : 'transparent',
        color: foreground,
        cursor: 'pointer',
        ':hover': {
            background: fade(hoverOpacity)
        }
    });

    let selected = style(`clickable-${name}-selected`, {
        background: fade(focusOpacity)
    });

    let disabled = style(`clickable-${name}-disabled`, {
        background: invert ? disabledBackground : 'transparent',
        color: disabledGreyColor,
        cursor: 'default'
    });

    let focused = style(`clickable-${name}-focused`, {
        background: fade(focusOpacity),
        ':hover': {
            background: fade(focusOpacity)
        }
    });

    let hiddenRipple = style(`clickable-${name}-noRipple`, {
        ':active': {
            background: fade(activeOpacity),
            transition: 'none'
        }
    });

    return { color: foreground, selected, focused, enabled, disabled, hiddenRipple };
}

let square = style('clickable-square', {
    ...roundedBorders
});

let circle = style('clickable-round', {
    borderRadius: '50%'
});

interface IRippleProps {
    left: number,
    top: number,
    size: number,
    color: Styles['color'],
    animationDone(): void
}

const Ripple = memo(function Ripple(props: IRippleProps) {
    return <div onAnimationEnd={props.animationDone} data-testid={testIds.ripple}><Styled.div styles={rippleStyle} inline={customRippleStyles(props)} /></div>;
});

function customRippleStyles({ size, left, top, color }: IRippleProps): React.CSSProperties {
    return {
        left: left,
        top: top,
        background: String(color),
        height: size,
        width: size
    };
}

let rippleOpacity = 0.2;

let rippleStyle = style('ripple', {
    position: 'absolute',
    transition: 'none',
    borderRadius: '100%',
    opacity: 0,
    zIndex: zIndexes.belowPage,
    animation: animation({
        0: {
            opacity: rippleOpacity,
            transform: 'scale(0)'
        },
        50: {
            opacity: 0.7 * rippleOpacity
        },
        100: {
            opacity: 0,
            transform: 'scale(2)'
        }
    }, duration.medium, 'ease-out', 0, 1)
});

export const testIds = {
    clickable: 'clickable',
    ripple: 'ripple'
};

export interface IClickableGroupProps<T> {
    selectedItem?: T;
    items: T[];
    onSelect(item: T): void;
    itemKey(item: T): string;
    children(item: T, helper: IFocusHelper): React.ReactNode;
}

export function ClickableGroup<T>(props: IClickableGroupProps<T>) {
    return <>
        {props.items.map(item => {
            let itemKey = props.itemKey(item);

            return <Clickable key={itemKey} onClick={() => props.onSelect(item)} selected={isSelected(itemKey)} tabIndex={0}>
                {helper => props.children(item, helper)}
            </Clickable>
        })}
    </>;

    function isSelected(itemKey: string) {
        return props.selectedItem === undefined
            ? false
            : props.itemKey(props.selectedItem) === itemKey;
    }
}
