import { useEffect } from "react";

export const isHoverSupported = matchMedia('(hover: hover)').matches;

export function useHover(elementRefs: React.RefObject<HTMLElement>[], onStartHovering: () => void, onStopHovering: () => void) {
    useEffect(() => {
        elements().forEach(el => {
            el.addEventListener(isHoverSupported ? 'mouseover' : 'contextmenu', onMouseOver);
            el.addEventListener('mouseout', onMouseOut);
        });

        return () => {
            elements().forEach(el => {
                el.removeEventListener(isHoverSupported ? 'mouseover' : 'contextmenu', onMouseOver);
                el.removeEventListener('mouseout', onMouseOut);
            });
        }
    });

    function onMouseOver(event: MouseEvent) {
        if (acrossBoundary(event)) {
            event.preventDefault(); // Prevents context menu on touch-and-hold
            onStartHovering();
        }
    }

    function onMouseOut(event: MouseEvent) {
        if (acrossBoundary(event))
            onStopHovering();
    }

    function acrossBoundary(event: MouseEvent) {
        return elements().none(el => el.contains(event.relatedTarget as Element));
    }

    function elements() { return elementRefs.map(e => e.current).notFalsy(); }
}
