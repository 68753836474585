import { MaybeArray } from "@zap/utils/lib/Types";
import * as CSS from "csstype";
import { animation, CSSProperties, defaultUnit, TLength } from "stylemap";
import { Hsla } from "./color";
export * from "stylemap";
export * from "./StyledComponent";

export const defaultPx = defaultUnit('px');

export const defaultMs = defaultUnit('ms');

export function important<T extends TLength>(value: T): T {
    return defaultPx(value) + ' !important' as T;
}

export function border(width: CSS.BorderWidthProperty<TLength>, style: CSS.BorderStyleProperty, color: CSS.Color): string {
    return `${defaultPx(width)} ${style} ${color}`;
}

export function boxShadow(offsetX: TLength, offsetY: TLength, color: CSSProperties['color']): string;
export function boxShadow(offsetX: TLength, offsetY: TLength, blurRadius: TLength, color: CSSProperties['color']): string;
export function boxShadow(offsetX: TLength, offsetY: TLength, blurRadius: TLength, spreadRadius: TLength, color: CSSProperties['color']): string;
export function boxShadow(offsetX: TLength, offsetY: TLength, blurRadius: TLength | CSSProperties['color'], spreadRadius?: TLength | CSSProperties['color'], color?: CSSProperties['color']): string {
    let parts = [] as string[];
    parts.push(defaultPx(offsetX));
    parts.push(defaultPx(offsetY));
    parts.push(defaultPx(blurRadius));
    if (spreadRadius != null)
        parts.push(defaultPx(spreadRadius));
    if (color != null)
        parts.push(color.toString());
    return parts.join(' ');
}

export function transition(property: string | 'all' | 'none', duration: number | string, timing?: CSS.TransitionTimingFunctionProperty, delay?: number | string): string;
export function transition(property: (string | 'all' | 'none')[], duration: number | string, timing?: CSS.TransitionTimingFunctionProperty, delay?: number | string): string[];
export function transition(property: MaybeArray<string | 'all' | 'none'>, duration: number | string, timing: CSS.TransitionTimingFunctionProperty = ease.default, delay: number | string = 0): string | string[] {
    let single = (p: string) => `${p} ${defaultMs(duration)} ${defaultMs(delay)} ${timing}`;
    return Array.isArray(property)
        ? property.map(single)
        : single(property);
}

export function hsl(hue: number, saturation: number, lightness: number): string {
    return `hsl(${hue}, ${saturation * 100}%, ${lightness * 100}%)`;
}
export function hsla(hue: number, saturation: number, lightness: number, alpha: number): string {
    return `hsla(${hue}, ${saturation * 100}%, ${lightness * 100}%, ${alpha})`;
}

export function lighten(color: CSS.Color, k?: number) {
    return Hsla.parse(color)!.brighter(k).toString();
}

export function darken(color: CSS.Color, k?: number) {
    return Hsla.parse(color)!.darker(k).toString();
}

export function fade(color: CSS.Color, alpha: number) {
    let hsl = Hsla.parse(color)!;
    return new Hsla(hsl.h, hsl.s, hsl.l, alpha).toString();
}

export type FlexDirection = CSS.Globals
    | 'row'
    | 'column'
    | 'row-reversed'
    | 'column-reversed';

export let ease = {
    default: 'cubic-bezier(.4, 0, .2, 1)',
    enter: 'cubic-bezier(0, 0, .2, 1)',
    exit: 'cubic-bezier(.4, 0, 1, 1)'
};

export let fadeIn = animation('fade-in', { from: { opacity: 0 }, to: { opacity: 1 } });
export let fadeOut = animation('fade-out', { from: { opacity: 1 }, to: { opacity: 2 } });