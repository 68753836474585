import * as React from "react";
import { noSpacing } from "./Box";
import { disabledGreyColor, duration, errorColor, fontSizes, labelFont, warningColor } from "./CommonStyles";
import { standardLabelStyle } from "./StandardLabel";
import { style, Styled, transition } from "./styling";

export interface IFieldHelperTextProps<T> {
    showEmptyHelper?: boolean;
    helperText?(value: T): string | undefined;
    warningText?(value: T): string | undefined;
    errorText?(value: T): string | undefined;
}

export interface IHelperTextProps {
    showEmptyHelper?: boolean;
    helperText?: string;
    warningText?: string;
    errorText?: string;
}

export function HelperText(props: IHelperTextProps) {
    let text = props.errorText
        || props.warningText
        || props.helperText;

    let color = props.errorText ? errorText
        : props.warningText ? warningText
            : defaultText;

    let show = text || props.showEmptyHelper;

    return <Styled.div styles={[helperTextStyle, color, show ? showHelper : hideHelper, !show && noSpacing]}>
        {text || <>&nbsp;</>}
    </Styled.div>;
}

let errorText = style('helper-error', { color: errorColor });
let warningText = style('helper-warning', { color: warningColor });
let defaultText = style('helper-default', { color: disabledGreyColor });

let helperTextStyle = style('helper-text', {
    ...labelFont,
    userSelect: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap', // TODO support multi-line helper
    transition: transition(['height', 'margin'], duration.small)
});

let showHelper = style('is-helper-visible', standardLabelStyle(fontSizes.label));

let hideHelper = style('is-helper-hidden', {
    margin: 0,
    height: 0
});
