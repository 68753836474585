import { mapValues } from "@zap/utils/lib/Object";
import * as React from 'react';
import { classes } from "stylemap";
import { disabledOpacity, hoverOpacity, interactiveOpacity, successColor, warningColor } from '../CommonStyles';
import { defaultPx, style } from '../styling';
import { Icons } from './Icons';

export type IconName = keyof typeof Icons;

export type IconSize = keyof typeof IconSizes;
export const IconSizes = {
    tiny: 16,
    small: 24,
    medium: 36,
    large: 48
};

export type DefaultColor = keyof typeof DefaultColors;
export const DefaultColors = {
    check: successColor,
    warning: warningColor
}

export type IconType = {
    name: IconName,
    color?: string,
    size?: IconSize
};

export function Icon({ name, color, size }: IconType) {
    let iconSize = size
        ? IconSizes[size]
        : IconSizes.small;

    return React.createElement(Icons[name], {
        'data-testid': `icon-${name}`,
        className: classes(icon),
        fill: color || DefaultColors[name as DefaultColor],
        height: defaultPx(iconSize),
        width: defaultPx(iconSize)
    } as React.SVGProps<SVGSVGElement>);
}

/** Use this to select the icon element. */
export const icon = style('icon', {});

/** For an icon that will be interactive. */
export const iconInteractive = style('icon-interactive', {
    pointerEvents: 'none', // IE 11 on Win 7: https://connect.microsoft.com/IE/feedback/details/796745/mouse-events-are-not-delivered-at-all-anymore-when-inside-an-svg-a-use-is-removed-from-the-dom
    opacity: interactiveOpacity
});

/** For an interactive icon for a component that is being hovered over, or is in focus. */
export const iconHover = style('icon-hover', { opacity: hoverOpacity });

/** For an interactive icon that's disabled. Marked as !important so hover effect isn't applied. */
export const iconDisabled = style('icon-disabled', { opacity: disabledOpacity });

export const testIds = mapValues(Icons, (_, name) => `icon-${name}`);