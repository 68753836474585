import { ITooltipForChildProps, ITooltipForExternalElementProps, Tooltip as BaseTooltip } from "@zap/ui/lib/Tooltip";
import * as React from "react";
import { ITooltipDefinition } from "./Desktop/TooltipService";

export type ITooltipProps = {
    definition?: ITooltipDefinition;
} & (ITooltipForExternalElementProps | ITooltipForChildProps);

export function Tooltip(props: ITooltipProps) {
    let { definition, ...rest } = props;

    if (definition) {
        rest.title = definition.title;
        rest.content = definition.text;
        rest.helpUrl = definition.helpUrl;
    }

    return <BaseTooltip helpText={WebCoreText.Label_MoreHelp} {...rest} />;
}