export class Rgba {
    readonly r: number;
    readonly g: number;
    readonly b: number;
    readonly a: number;

    constructor(r: number, g: number, b: number, a: number) {
        this.r = Math.round(r);
        this.g = Math.round(g);
        this.b = Math.round(b);
        this.a = a;
    }

    brighter(k: number = 1) {
        k = Math.pow(.7, k);

        var r = brighterComponent(this.r, k);
        var g = brighterComponent(this.g, k);
        var b = brighterComponent(this.b, k);

        return new Rgba(r, g, b, this.a);

        function brighterComponent(component: number, k: number) {
            return component < 30
                ? 30
                : Math.min(255, component / k);
        }
    }

    darker(k: number = 1) {
        k = Math.pow(.7, k);
        return new Rgba(k * this.r, k * this.g, k * this.b, this.a);
    }

    get hexString() {
        return "#"
            + this.r.toString(16)
            + this.g.toString(16)
            + this.b.toString(16)
            + (this.a * 256).toString(16);
    }

    get rgbaString() {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }

    toString() {
        return this.rgbaString;
    }

    toHsla() {
        return Hsla.fromRgb(this.r, this.g, this.b, this.a);
    }

    static fromHex6(hex: string, alpha: number = 1) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        if (!result)
            return null;

        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        var a = alpha;

        return new Rgba(r, g, b, a);
    }

    /**
     * Stolen from https://github.com/mjackson/mjijackson.github.com/blob/master/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript.txt
     * @param h - 0-360
     * @param s - 0-1
     * @param l - 0-1
     * @param a - 0-1
     */
    static fromHsl(h: number, s: number, l: number, a: number) {
        if (s === 0)
            return new Rgba(l * 255, l * 255, l * 255, a);

        h /= 360;

        var q = l < 0.5
            ? l * (1 + s)
            : l + s - l * s;

        var p = 2 * l - q;

        var r = getComponent(1 / 3);
        var g = getComponent(0);
        var b = getComponent(-1 / 3);

        return new Rgba(r * 255, g * 255, b * 255, a);

        function getComponent(offset: number) {
            var t = h + offset;

            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }
    }
}

export class Hsla {
    /**
     * @param h 0-360
     * @param s 0-1
     * @param l 0-1
     * @param a 0-1
     */
    constructor(
        public readonly h: number,
        public readonly s: number,
        public readonly l: number,
        public readonly a: number
    ) { }

    brighter(k: number = 1) {
        return new Hsla(this.h, this.s, this.l / Math.pow(.7, k), this.a);
    }

    darker(k: number = 1) {
        return new Hsla(this.h, this.s, Math.pow(.7, k) * this.l, this.a);
    }

    get hslaString() {
        return `hsla(${this.h}, ${this.s * 100}%, ${this.l * 100}%, ${this.a})`;
    }

    toString() {
        return this.hslaString;
    }

    toRgba() {
        return Rgba.fromHsl(this.h, this.s, this.l, this.a);
    }

    toHex6() {
        let rgba = this.toRgba();
        return `#${hex(rgba.r)}${hex(rgba.g)}${hex(rgba.b)}`;
    }

    fade(opacity: number) {
        return new Hsla(this.h, this.s, this.l, this.a * opacity);
    }

    static fromHex6(hex: string, alpha: number) {
        var color = Rgba.fromHex6(hex, alpha);
        return color ? Hsla.fromRgb(color.r, color.g, color.b, color.a) : null;
    }

    /**
     * Stolen from https://github.com/mjackson/mjijackson.github.com/blob/master/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript.txt
     * @param red - 0-255
     * @param green - 0-255
     * @param blue - 0-255
     * @param alpha - 0-1
     */
    static fromRgb(red: number, green: number, blue: number, alpha: number) {
        var r = red / 255;
        var g = green / 255;
        var b = blue / 255;

        var max = Math.max(r, g, b);
        var min = Math.min(r, g, b);

        var l = (max + min) / 2;
        if (max == min)
            return new Hsla(0, 0, l, alpha);

        var d = max - min;
        var s = l > 0.5
            ? d / (2 - max - min)
            : d / (max + min);

        var h = max === r ? (g - b) / d + (g < b ? 6 : 0)
            : max === g ? (b - r) / d + 2
                : (r - g) / d + 4;

        h /= 6;

        return new Hsla(h * 360, s, l, alpha);
    }

    static parse(colorString: string) {
        if (colorString[0] == '#')
            return Hsla.fromHex6(colorString, 1);

        let hsl = /hsl\(\s*([\d\.]+)\s*,\s*([\d\.]+)%\s*,\s*([\d\.]+)%\s*\)/.exec(colorString);
        if (hsl)
            return new Hsla(parseFloat(hsl[1]), parseFloat(hsl[2]) / 100, parseFloat(hsl[3]) / 100, 1);

        let hsla = /hsla\(\s*([\d\.]+)\s*,\s*([\d\.]+)%\s*,\s*([\d\.]+)%\s*,\s*([\d\.]+)\s*\)/.exec(colorString);
        if (hsla)
            return new Hsla(parseFloat(hsla[1]), parseFloat(hsla[2]) / 100, parseFloat(hsla[3]) / 100, parseFloat(hsla[4]));

        let rgba = /rgba?\(\s*([\d\.]+)\s*,\s*([\d\.]+)\s*,\s*([\d\.]+)\s*(?:,\s*([\d\.]+)\s*)?\)/.exec(colorString);
        if (rgba)
            return new Rgba(parseFloat(rgba[1]), parseFloat(rgba[2]), parseFloat(rgba[3]), rgba[4] ? parseFloat(rgba[4]) : 1).toHsla();

        return null;
    }
}

function hex(value: number) {
    return Number(value).toString(16).padStart(2, '0');
}
