import * as React from 'react';
import { useState } from "react";
import { forwardRef } from "@zap/utils/lib/ReactHelpers";

export interface IFocusHelperProps {
    disabled?: boolean;
    children(helper: IFocusHelper, ref: React.Ref<any>): React.ReactElement<any>;
}

export interface IFocusHelper {
    getFocusProps<T extends object>(conflictingProps?: T): IFocusProps & T;
    isFocused: boolean;
}

export interface IFocusProps {
    onFocus: React.FocusEventHandler;
    onBlur: React.FocusEventHandler;
}

export const FocusHelper = forwardRef(function FocusHelper(props: IFocusHelperProps, ref: React.Ref<any>) {
    return props.children(useFocus(props.disabled), ref);
});

export function useFocus(disabled?: boolean) {
    let [isFocused, setFocused] = useState(false);
    if (isFocused && disabled)
        setFocused(false);
    return {
        getFocusProps: <T extends object>(conflictingProps?: T) => {
            let { onFocus, onBlur, ...rest } = (conflictingProps || {}) as IFocusProps;
            return {
                onFocus: (e: React.FocusEvent) => {
                    setFocused(true);
                    if (onFocus) onFocus(e);
                },
                onBlur: (e: React.FocusEvent) => {
                    setFocused(false);
                    if (onBlur) onBlur(e);
                },
                ...rest
            } as IFocusProps & T;
        },
        isFocused: isFocused && !disabled
    };
}