import { standardBorder } from "./CommonStyles";
import { style, Styles } from "./styling";

export let topDivider = divider('divider-top', {
    top: 0,
    width: '100%',
    borderTop: standardBorder()
});

export let bottomDivider = divider('divider-bottom', {
    bottom: 0,
    width: '100%',
    borderBottom: standardBorder()
});

export let rightDivider = divider('divider-right', {
    right: 0,
    height: '100%',
    borderRight: standardBorder()
});

function divider(name: string, sideStyles: Styles) {
    return style(name, {
        position: 'relative',
        '::before': {
            content: '""',
            position: 'absolute',
            ...sideStyles
        }
    });
}