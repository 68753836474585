import { setFrom } from "@zap/utils/lib/EventHelpers";
import { LoadState } from "@zap/utils/lib/LoadState";
import { derived, event, events, reduce, reduced } from "event-reduce";

@events
export class LoginEvents {
    usernameChanged = event<string>();
    passwordChanged = event<string>();
    rememberMeChanged = event<{ enabled: boolean }>();
    authenticated = event<Promise<void>>();
    challenged = event();
}

export class LoginModel {
    events = new LoginEvents();

    @reduced
    username = setFrom('', this.events.usernameChanged);

    @reduced
    password = setFrom('', this.events.passwordChanged);

    @reduced
    rememberMe = setFrom(false, this.events.rememberMeChanged.map(c => c.enabled));

    @derived
    get usernameValidity() {
        return !this.username
            ? false
            : undefined;
    }

    @derived
    get passwordValidity() {
        return !this.password
            ? false
            : undefined;
    }

    @derived
    get usernameError() {
        return !this.username ? PageText.Login_UserNameRequired : '';
    }

    @derived
    get passwordError() {
        return !this.password ? PageText.Login_PasswordRequired : '';
    }

    @reduced
    loginAttempted = reduce(false, this.events)
        .on(e => e.authenticated, () => true)
        .value;

    @reduced
    loginError = reduce('', this.events)
        .on(e => e.authenticated, () => '')
        .on(e => e.authenticated.rejected(), (_, error) => error.AdvancedMessage || error.Message || error.message)
        .value;

    loggingIn = new LoadState(this.events.authenticated);

    @reduced
    hasChallenged = reduce(false, this.events)
        .on(e => e.challenged, () => true)
        .value;
}