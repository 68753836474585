import { lineHeight, standardSpacing } from "./CommonStyles";
import { Styles } from "./styling";

export let defaultLabelHeight = standardSpacing;
export let defaultDescenderHeight = 3;

/** Generates styles for a label that ensures descenders are rendered without affecting layout */
export function standardLabelStyle(fontSize: number, totalHeight = defaultLabelHeight, descenderHeight = defaultDescenderHeight): Styles {
    let height = Math.ceil(fontSize * lineHeight);
    let topMargin = totalHeight - height + descenderHeight;
    return {
        fontSize,
        height: height,
        lineHeight: height + 'px',
        margin: [topMargin, 0, -descenderHeight, 0]
    };
}