import { reduce } from "event-reduce";
import { IObservable, ISimpleObservable, Observable } from "event-reduce/lib/observable";
import { LoadState, ObservableLoadState } from "./LoadState";

export type ExtendedPromise<TPromise extends object | null, TExtra> = PromiseLike<TPromise & TExtra> & TExtra;
export type ExtendedObservable<TObservable extends object | null, TExtra> = ISimpleObservable<TObservable & TExtra> & TExtra;

export function extendPromise<TPromise extends object | null, TExtra extends object>(promise: PromiseLike<TPromise>, extra: TExtra): ExtendedPromise<TPromise, TExtra> {
    return Object.assign(promise.then(v => extendNullableObject(v, extra)), extra);
}

export function extendObservable<TObservable extends object | null, TExtra extends object>(observable: IObservable<TObservable>, extra: TExtra): ExtendedObservable<TObservable, TExtra> {
    return Object.assign(new Observable<TObservable & TExtra>(
        observer => observable.subscribe(
            v => observer.next(extendNullableObject(v, extra)),
            e => observer.error(e),
            () => observer.complete())),
        extra);
}

function extendNullableObject<T extends object | null, TExtra extends object>(v: T, extra: TExtra) {
    return Object.assign(v || {}, extra) as T & TExtra;
}

export function setFrom<T>(initial: T, setterEvent: IObservable<T>) {
    return reduce(initial).on(setterEvent, (_, value) => value).value;
}

export function setAndReset<T>(initial: T, setterEvent: IObservable<T>, resetEvent: IObservable<any>): T {
    return reduce(initial)
        .on(setterEvent, (_, value) => value)
        .on(resetEvent, () => initial)
        .value;
}

export function loadStateMap<T extends PromiseLike<any>>(event: IObservable<T>, getKey: (promise: T) => string) {
    return reduce({} as { [key: string]: LoadState })
        .on(event, (all, promise) => ({ ...all, [getKey(promise)]: LoadState.forPromise(promise) }))
        .value;
}

export function observableLoadStateMap<T extends IObservable<any>>(event: IObservable<T>, getKey: (promise: T) => string) {
    return reduce({} as { [key: string]: ObservableLoadState })
        .on(event, (all, observable) => ({ ...all, [getKey(observable)]: ObservableLoadState.forObservable(observable) }))
        .value;
}

export function resultMap<T extends object | null, TKeyed>(event: ISimpleObservable<ExtendedPromise<T, TKeyed>>, getKey: (item: TKeyed) => string, defaultValue?: T) {
    let reduction = reduce({} as { [key: string]: T });
    if (typeof defaultValue != 'undefined')
        reduction = reduction.on(event, (all, promise) => ({ ...all, [getKey(promise)]: defaultValue }));
    return reduction.on(event.resolved(), (all, result) => ({ ...all, [getKey(result)]: result }))
        .value;
}
