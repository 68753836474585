export function extendPrototype<T>(constructor: { new(): T }, methods: Partial<T>) {
    let properties = {} as PropertyDescriptorMap;
    (Object.keys(methods) as (Extract<keyof T, string>)[]).forEach(methodName => {
        if (!(methodName in constructor.prototype))  // Avoid overwriting native methods
            properties[methodName] = {
                value: methods[methodName],
                enumerable: false,
                writable: true
            };
    });

    Object.defineProperties(constructor.prototype, properties);
}