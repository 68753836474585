import { reduce, reduced } from "event-reduce";
import { IObservable, ISimpleObservable } from "event-reduce/lib/observable";
import { Subject } from 'event-reduce/lib/subject';
import { computed } from 'mobx';

export interface IException {
    message: string;
    innerException?: IException;
    $type?: string;
}

export interface ILoadState {
    isUninitialized: boolean;
    isLoading: boolean;
    hasEverLoaded: boolean;
    error: IException | undefined;
}

export class LoadState implements ILoadState {
    constructor(private _event: ISimpleObservable<PromiseLike<any>>) { }

    @computed
    get isUninitialized() {
        return !this.hasEverLoaded
            && !this.isLoading;
    }

    @reduced
    isLoading = reduce(false)
        .on(this._event, () => true)
        .on(this._event.resolved(), () => false)
        .on(this._event.rejected(), () => false)
        .value;

    @reduced
    hasEverLoaded = reduce(false)
        .on(this._event.resolved(), () => true)
        .value;

    @reduced
    error = reduce(undefined as IException | undefined)
        .on(this._event, () => undefined)
        .on(this._event.rejected(), (_, e) => e || { message: "Unknown Error" })
        .value;

    @reduced
    loaded = reduce(unresolvedPromiseLike())
        .on(this._event, (_, p) => voidPromise(p))
        .value;

    static forPromise(promise: PromiseLike<any>) {
        let subject = new Subject<any>();
        let loadState = new LoadState(subject);
        subject.next(promise);
        return loadState;
    }

    static never() {
        return new LoadState(new Subject<any>());
    }
}

export function allEvents(...events: ISimpleObservable<PromiseLike<any>>[]) {
    let reduction = reduce(events.map(unresolvedPromiseLike));
    events.forEach((event, index) =>
        reduction.on(event, (allPromises, newPromise) => insertNewPromise(allPromises, index, newPromise)));
    return reduction.map(ps => voidPromise(Promise.all(ps)));

    function insertNewPromise(promises: PromiseLike<void>[], index: number, newPromise: PromiseLike<any>) {
        return promises.map((p, i) => i == index ? voidPromise(newPromise) : p);
    }
}

function unresolvedPromiseLike(): PromiseLike<void> {
    return new Promise<void>(() => { });
}

function voidPromise(promise: PromiseLike<any>) {
    return promise.then(() => { });
}

export class ObservableLoadState implements ILoadState {
    constructor(private _event: ISimpleObservable<IObservable<any>>) { }

    @computed
    get isUninitialized() {
        return !this.hasEverLoaded
            && !this.isLoading;
    }

    @reduced
    isLoading = reduce(false)
        .on(this._event, () => true)
        .on(this._event.completed(), () => false)
        .on(this._event.errored(), () => false)
        .value;

    @reduced
    hasEverLoaded = reduce(false)
        .on(this._event.completed(), () => true)
        .value;

    @reduced
    error = reduce(undefined as IException | undefined)
        .on(this._event, () => undefined)
        .on(this._event.errored(), (_, e) => e || { message: "Unknown Error" })
        .value;

    static forObservable(observable: IObservable<any>) {
        let subject = new Subject<IObservable<any>>();
        let loadState = new ObservableLoadState(subject);
        subject.next(observable);
        return loadState;
    }
}
