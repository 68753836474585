import * as React from "react";
import { cloneAndGetRef } from "@zap/utils/lib/ReactHelpers";
import { useRef, useEffect } from "react";

export interface IAutoScrollProps {
    enabled?: boolean;
}

export class AutoScroll extends React.Component<IAutoScrollProps> {
    private _hasScrolled = false;

    render(): React.ReactElement<any> {
        return cloneAndGetRef(this.props.children, (element) => this.scrollTo(element));
    }

    scrollTo(e: Element | null) {
        if (!this._hasScrolled && e) {
            if (this.props.enabled)
                e.scrollIntoView();
            this._hasScrolled = true;
        }
    }
}

export function useScrollTo<TElement extends Element>(scroll: boolean) {
    let ref = useRef<TElement>(null);

    useEffect(() => {
        if (scroll && ref.current)
            ref.current.scrollIntoView();
    }, [scroll]);

    return ref;
}