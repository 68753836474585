import { dataProps, forwardRef, useMaybeControlledState } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { Column, Row } from "./Box";
import { defaultFont, disabledGreyColor, duration, greyColor, highlightColor, lightGreyColor, standardFormControlHeight } from "./CommonStyles";
import { fieldAsRef, IFormFieldProps, useFormOnKeyDown } from "./Form";
import { Elevation, shadow } from "./shadow";
import { darken, lighten, style, Styled, transition } from "./styling";

export interface ISwitchProps extends IFormFieldProps<HTMLInputElement> {
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label?: string;
}

export const Switch = forwardRef(function Switch(props: ISwitchProps, ref: React.Ref<HTMLLabelElement>) {
    let fieldRef = fieldAsRef(props.field);
    let formKeyDown = useFormOnKeyDown();
    let [checked, setChecked] = useMaybeControlledState(props.defaultChecked || false, props.checked);

    return <label ref={ref} role="switch" aria-checked={!!checked} {...dataProps(props)} onKeyDown={formKeyDown}>
        <Row justifyContent="space-between" center height={standardFormControlHeight}>
            <Styled.div styles={defaultFont}>{props.label}</Styled.div>
            <Column noSpacing>
                <Styled.input ref={fieldRef} styles={checkBoxStyle} type="checkbox" checked={checked} onChange={change} disabled={props.disabled} />
                <Styled.span styles={switchStyle} />
            </Column>
        </Row>
    </label>;

    function change(e: React.ChangeEvent<HTMLInputElement>) {
        setChecked(e.target.checked);
        if (props.onChange)
            props.onChange(e);
    }
});

let checkBoxStyle = style('switch-checkbox', {
    opacity: 0,
    position: 'absolute',
    ':focus': {
        outline: 'none'
    }
});

let switchStyle = style('switch', {
    display: 'inline-block',
    width: 36,
    height: 24,
    position: 'relative',
    '::before': {
        content: '""',
        position: 'absolute',
        left: 1,
        right: 1,
        top: 5,
        height: 14,
        borderRadius: 7,
        opacity: 0.5,
        background: greyColor,
        transition: transition('all', duration.small),
        $: {
            [`.${checkBoxStyle}:checked + &`]: {
                background: highlightColor
            },
            [`.${checkBoxStyle}:disabled + &`]: {
                background: lighten(disabledGreyColor)
            }
        }
    },
    '::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 2,
        width: 20,
        background: 'white',
        borderRadius: '50%',
        ...shadow(Elevation.Switch),
        height: 20,
        transition: transition(['transform', 'background'], duration.small),
        $: {
            [`.${checkBoxStyle}:focus + &`]: {
                background: lightGreyColor
            },
            [`.${checkBoxStyle}:checked + &`]: {
                transform: { translateX: 16 },
                background: highlightColor
            },
            [`.${checkBoxStyle}:focus:checked + &`]: {
                background: darken(highlightColor)
            },
            [`.${checkBoxStyle}:disabled + &`]: {
                background: disabledGreyColor
            }
        }
    }
});
