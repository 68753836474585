import '@zap/utils/lib/extensions';
import * as React from 'react';
import htmlTags from './HtmlTags';
import { classes, StyleCollection } from 'stylemap';

export interface IStyledProps {
    styles: StyleCollection;
    inline?: React.CSSProperties;
}

export const Styled = Object.assign(
    React.forwardRef(function Styled(props: IStyledProps & { children?: React.ReactNode }, ref?: React.Ref<any>) {
        let child = React.Children.only(props.children as any);
        return React.cloneElement(child, styledProps(props.styles, child.props.className, props.inline, ref));
    }),
    htmlTags.toObject(tag => tag, tag => {
        return Object.assign(React.forwardRef(function Styled(props: IStyledProps & { className?: string }, ref?: React.Ref<any>) {
            let { styles, inline, className, ...rest } = props;
            return React.createElement(tag, {
                ...styledProps(styles, className, inline, ref),
                ...rest
            });
        }), { displayName: `Styled.${tag}` });
    })) as unknown as StyledComponent;

function styledProps(styles: StyleCollection, className?: string, inline?: React.CSSProperties, ref?: React.Ref<any>) {
    return {
        className: [classes(styles), className].join(' '),
        style: inline,
        ref: ref
    };
}

export type StyledComponent = React.StatelessComponent<IStyledProps>
    & { [E in keyof JSX.IntrinsicElements]: React.StatelessComponent<IStyledProps & JSX.IntrinsicElements[E]> };
